export const AUTH_REQUEST		= 'AUTH_REQUEST'
export const AUTH_SUCCESS		= 'AUTH_SUCCESS'
export const AUTH_ERROR			= 'AUTH_ERROR'
export const AUTH_LOGOUT		= 'AUTH_LOGOUT'
export const AUTH_CLEAR			= 'AUTH_CLEAR'
export const AUTH_UNAUTHORIZED	= 'AUTH_UNAUTHORIZED'
export const AUTH_NETWORKERROR	= 'AUTH_NETWORKERROR'
export const AUTH_REGISTER		= 'AUTH_REGISTER'
export const LOGIN_OPEN			= 'LOGIN_OPEN'
export const LOGIN_CLOSE		= 'LOGIN_CLOSE'
export const AUTH_ADMIN			= 'AUTH_ADMIN'
export const OFF_WELCOME_MESSAGE= 'OFF_WELCOME_MESSAGE'
