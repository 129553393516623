<template>
	<Admin v-if="this.isAuthenticated && this.isAdmin" />
	<User v-else-if="this.isAuthenticated" />
	<Page v-else />
</template>

<script>

import { mapGetters } from 'vuex';

import User from	'./views/userLayout/Main.vue'
import Page from	'./views/publicLayout/Main.vue'
import Admin from	'./views/adminLayout/Main.vue'

export default {
	name: 'ALSTERPH',
	components: {
		User,
		Page,
		Admin
	},
	computed:{
		...mapGetters(['isAuthenticated', 'isAdmin'])
	},
};
</script>

<style scoped>

	>>> html, body{
		height:100%;
		
	}
</style>