<template>
	<!-- App.vue -->
	<v-app v-resize="onUserResize">
		<v-app-bar
			elevation= "0"
			app
			ref="userAppBar"
			dense
			class="ml-n1 mr-n3"
		>
			<v-tooltip bottom>
				<template v-slot:activator="{ on: leftMenu }">
					<v-btn
						v-on="leftMenu"
						small
						depressed
						fab
						@click.stop="toggleNavDrawer()"
					>
						<v-icon>{{ navIcon }}</v-icon>
					</v-btn>
				</template>
				<span>Toggle</span>
			</v-tooltip>
			
			<v-toolbar-title class="headline font-weight-regular">{{ routeName }}</v-toolbar-title>
			
			<v-spacer></v-spacer>
			
			<v-menu offset-y>
				
				<template v-slot:activator="{ on: rightMenu, attrs }">
					<v-tooltip left>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								small
								depressed
								class="mr-3"
								v-bind="attrs"
								v-on="{ ...tooltip, ...rightMenu }"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<span class="text-caption">Menu</span>
					</v-tooltip>
				</template>
				
				<v-list dense>
					<v-list-item @click="$router.push('account');">
						<v-list-item-icon>
							<v-icon>mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
						<v-list-item-title>ACCOUNT</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider></v-divider>
					
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
						<v-list-item-title>LOG OUT</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
				
		</v-app-bar>
		
		<v-navigation-drawer
			app
			v-model="navDrawer2"
			:mini-variant.sync="navMini"
			:permanent="navPermanent"
			:expand-on-hover="navHover"
			color="primary"
			dark
		>
			<v-list-item dark class="pl-2">
				<v-list-item-avatar tile>
					<v-icon>mdi-plus</v-icon>
				</v-list-item-avatar>
				<v-list-item-title>&nbsp;</v-list-item-title>
			</v-list-item>
		</v-navigation-drawer>
		
		<v-navigation-drawer
			v-model="navDrawer"
			:mini-variant.sync="navMini"
			:permanent="navPermanent"
			:expand-on-hover="navHover"
			color="primary"
			dark
			absolute
			style="z-index:10;"
		>
			<v-list-item dark dense class="pl-2">
				<v-list-item-avatar tile class="my-1 py-1">
					<v-img height="40" :src="require('@/assets/images/logo/alsterlogowhiteIcon.png')"></v-img>
				</v-list-item-avatar>
				<v-list-item-title class="text-body-1"><strong>ALSTERPH</strong></v-list-item-title>
			</v-list-item>
			
			<v-divider></v-divider>
			
			<v-list-item class="pl-2">
				<v-list-item-avatar>
					<v-img :src="imageProfile"></v-img>
				</v-list-item-avatar>
				
				<v-list-item-title class="text-body-2 font-weight-bold">
					ADMINISTRATOR
				</v-list-item-title>
			</v-list-item>

			<v-divider></v-divider>
			
			<v-list dense ref="userMenuOption">
				<template v-for="item in mainMenu">
					<v-list-item
						link
						:key="item.title"
						:to="item.path == '' ? '' : item.path"
						:exact=true
					>
						<v-list-item-icon class="mr-6">
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</template>
			</v-list>

		</v-navigation-drawer>
		
		<!-- Sizes your content based upon application components -->
		<v-main>

			<!-- Provides the application the proper gutter -->
			<v-container fluid class="mt-0 pt-0 pr-0 mr-0 pb-0 mb-0">
				
				<!-- If using vue-router -->
				<router-view></router-view>
				
			</v-container>
		</v-main>
	</v-app>
</template>

<script>

import { mapGetters } from 'vuex';

import { AUTH_LOGOUT } from '@/store/actions/auth';
import { USER_RESIZE, IMAGE_PROFILE_LOAD } from '@/store/actions/user';

var breakpointName	= '';
var timeOutNav		= null;

export default {
	data () {
		return {
			snackBar: true,
			windowWidth	: 0,
			windowHeight: 0,
			navUser		: false,
			navMini		: false,
			navHover	: false,
			navDrawer	: null,
			navDrawer2	: null,
			navPermanent: false,
			appBarHeight: 0,
			
			mainMenu: [
				{ title: 'DASHBOARD',		icon: 'mdi-view-dashboard',			path: '/' 			},
				{ title: 'APPLICANTS', 		icon: 'mdi-account-box-multiple',	path: '/applicants'	},
			],
		}
    },
	methods: {
		onUserResize () {
			
			if( this.windowWidth != window.innerWidth || this.windowHeight != window.innerHeight ){
			
				if( this.windowWidth != window.innerWidth ){
					if(window.innerWidth <= 600){
						breakpointName = 'xs';
					}else if(window.innerWidth > 600 && window.innerWidth <= 960){
						breakpointName = 'sm';
					}else{
						breakpointName = 'mdAndUp';
					}
					
					switch(breakpointName){
						case 'xs':
							this.navPermanent = false;
							this.navDrawer = false;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								
								this.navMini = this.navHover = false;
								
							}, 5);
						break;
						case 'sm':
							this.navMini = this.navHover = true;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								this.navPermanent = true;
							}, 5);
						break;
						case 'mdAndUp':
							if( !this.navUser )this.navMini = this.navHover = false;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								this.navPermanent = true;
							}, 5);
						break;
					}
				}
				
				this.windowWidth = window.innerWidth;
				this.windowHeight = window.innerHeight
				this.appBarHeight = this.$refs.userAppBar.$el.clientHeight;
				let { appBarHeight } = this;
				this.$store.dispatch(USER_RESIZE, { appBarHeight });
			}
			
		},
		logout(){
			this.$store.dispatch(AUTH_LOGOUT).then(() => {
				if( this.$route.name != '' )this.$router.push('/');
			});
		},
		toggleNavDrawer(){
			if( !this.navDrawer )this.navDrawer = !this.navDrawer;
			this.navHover = this.navMini = (this.$vuetify.breakpoint.xsOnly)? false : !this.navMini;
			this.navUser = !this.navUser;
		}
	},
	computed: {
		...mapGetters(['imageProfile']),
		routeName () {
			return this.$route.name == '' ? 'DASHBOARD' : this.$route.name;
		},
		navIcon () {
			return this.navMini || this.$vuetify.breakpoint.xsOnly ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical';
		},
	},
	created () {
		// fetch the data when the view is created and the data is
		// already being observed
		this.$store.dispatch(IMAGE_PROFILE_LOAD);
	},
	mounted(){
		document.documentElement.style.overflowY  = "hidden";
	},
	beforeDestroy(){
		clearTimeout(timeOutNav);
	},
}
</script>


<style scoped>
	>>> .v-tooltip__content{
		padding: 3px 16px;
	}
</style>