import axios from 'axios'

import { STORE_INFO, USER_RESIZE, SET_DATE, USER_CLEAR } from '../actions/user';
import { CLEAR_ALERT, ERROR_VIEW, ERROR_NETWORK } from '../actions/user';
import { SUCCESS_CREATE, SUCCESS_UPDATE, SUCCESS_DELETE } from '../actions/user';
import { ERROR_CREATE_FORM, ERROR_CREATE_SERVER, ERROR_UPDATE_FORM, ERROR_UPDATE_SERVER, ERROR_DELETE_SERVER } from '../actions/user';
import { UPLOAD_PROFILE_OPEN, UPLOAD_PROFILE_CLOSE, IMAGE_PROFILE_LOAD } from '../actions/user';

var alertTimeout = "";

const state = { 
	userFormHeight	: 0,
	serverDate		: '',
	windowSize		: '',
	
	errorOn			: false,
	errorType		: 'success',
	errorMessage	: '',
	
	uploadProfile	: false,
	imageProfile	: '',
	rank			: '',
	fname			: '',
}

const getters = {
	userFormHeight	: state => state.userFormHeight,
	serverDate		: state => state.date,
	windowSize		: state => state.windowSize,
	
	uploadProfile	: state => state.uploadProfile,
	imageProfile	: state => state.imageProfile,
	
	rank			: state => state.rank,
	fname			: state => state.fname,
	
	errorOn			: state => state.errorOn,
	errorType		: state => state.errorType,
	errorMessage	: state => state.errorMessage,
}
 
const actions = {
	[USER_RESIZE]: ({commit}, data) => {
		if( typeof data !== 'undefined'){
			commit(USER_RESIZE, data.appBarHeight)
		}
	},
	[SET_DATE]: ({commit}, date) => {
		commit(SET_DATE, date)
	},
	[STORE_INFO]: ({commit}, data) => {
		commit(STORE_INFO, data)
	},
	
	[CLEAR_ALERT]: ({commit}) => {
		commit(CLEAR_ALERT)
	},
	
	[SUCCESS_CREATE]: ({commit}, msg) => {
		commit(SUCCESS_CREATE, msg.msg)
	},
	[SUCCESS_UPDATE]: ({commit}, msg) => {
		commit(SUCCESS_UPDATE, msg.msg);
	},
	[SUCCESS_DELETE]: ({commit}, msg) => {
		commit(SUCCESS_DELETE, msg.msg)
	},
	
	[ERROR_VIEW]: ({commit}, msg) => {
		commit(ERROR_VIEW, msg.msg)
	},
	[ERROR_CREATE_FORM]: ({commit}, msg) => {
		commit(ERROR_CREATE_FORM, msg.msg)
	},
	[ERROR_CREATE_SERVER]: ({commit}, msg) => {
		commit(ERROR_CREATE_SERVER, msg.msg)
	},
	[ERROR_UPDATE_FORM]: ({commit}, msg) => {
		commit(ERROR_UPDATE_FORM, msg.msg)
	},
	[ERROR_UPDATE_SERVER]: ({commit}, msg) => {
		commit(ERROR_UPDATE_SERVER, msg.msg)
	},
	[ERROR_DELETE_SERVER]: ({commit}, msg) => {
		commit(ERROR_DELETE_SERVER, msg.msg)
	},
	[ERROR_NETWORK]: ({commit}) => {
		commit(ERROR_NETWORK)
	},
	
	[UPLOAD_PROFILE_OPEN]: ({commit}) => {
		commit(UPLOAD_PROFILE_OPEN)
	},
	[UPLOAD_PROFILE_CLOSE]: ({commit}) => {
		commit(UPLOAD_PROFILE_CLOSE)
	},
	[IMAGE_PROFILE_LOAD]: ({commit}) => {
		
		axios.get('profilePicture')
		.then(function (resp){
			commit(IMAGE_PROFILE_LOAD, resp.data.image)
		});
	},
	[USER_CLEAR]: ({commit}) => {
		commit(USER_CLEAR)
	},
}

const mutations = {
	[USER_RESIZE]: (state, appBarHeight) => {
		
		if( typeof appBarHeight !== 'undefined'){
			state.userFormHeight = window.innerHeight - appBarHeight;
		}
		
		state.windowSize = window.innerWidth+"x"+window.innerHeight;
		
	},
	[SET_DATE]: (state, date) => {
		state.date = date.date;
	},
	[STORE_INFO]: (state, data) => {
		state.rank = data.rank;
		state.fname = data.name;
	},
	[UPLOAD_PROFILE_OPEN]: (state) => {
		state.uploadProfile = true;
	},
	[UPLOAD_PROFILE_CLOSE]: (state) => {
		state.uploadProfile = false;
	},
	[IMAGE_PROFILE_LOAD]: (state, img) => {
		state.imageProfile = img;
	},
	
	[CLEAR_ALERT]: (state) => {
		clearTimeout(alertTimeout);
		
		state.errorOn = false;
		state.errorType = 'success';
		state.errorMessage = '';
	},
	
	[SUCCESS_CREATE]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'success';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Successfully created' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	[SUCCESS_UPDATE]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'success';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Successfully updated' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	[SUCCESS_DELETE]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'success';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Successfully deleted' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	
	
	[ERROR_VIEW]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Server error' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	[ERROR_CREATE_FORM]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Create failed, invalid form' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},	
	[ERROR_CREATE_SERVER]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Create failed, server error' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	[ERROR_UPDATE_FORM]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Update failed, invalid form' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},	
	[ERROR_UPDATE_SERVER]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Update failed, server error' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	
	[ERROR_DELETE_SERVER]: (state, msg) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = (window.innerWidth <= 600 || typeof msg === 'undefined')? 'Delete failed, server error' : msg;
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 10000);
	},
	
	[ERROR_NETWORK]: (state) => {
		state.errorOn = true;
		state.errorType = 'error';
		state.errorMessage = 'Network error';
		
		clearTimeout(alertTimeout);
		alertTimeout = setTimeout(() => { state.errorOn = false; state.errorMessage = ''; }, 60000);
	},
	[USER_CLEAR]: (state) => {
		state.imageProfile	= '';
		state.rank			= '';
		state.fname			= '';
	},
	
}

export default {
	state,
	getters,
	actions,
	mutations,
}