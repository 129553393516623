<template>
	<v-container fluid class="px-0 mx-0">
		<v-card
			elevation="0"
			v-intersect="pageViewIn"
			:min-height="this.$store.getters.pageHeight"
			class="ma-0 pa-0"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
				class="mt-16"
			>
				<v-col
					cols="12"
					v-intersect="onIntersect"
					name="animate__animated animate__slideInDown"
					class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text-center text-uppercase font-weight-regular elementHide"
				>
					{{ page }}
				</v-col>
				
				<v-col
					cols="11" sm="11" md="10" lg="9" xl="8"
					v-intersect="onIntersect"
					name="animate__animated animate__slideInUp"
					class="mt-5 mb-5 mb-sm-10 mb-md-14 mb-lg-16 mb-xl-16 text-center font-weight-light text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 overflowHide elementHide"
				>
					{{ aboutUsText }}
				</v-col>
			</v-row>
			
			<v-img
				:height="aboutUsImageH"
				position="top right"
				:src="require('@/assets/images/alster/pageAbout.jpg')"
				alt="Home Image"
				class="d-flex"
			>
				
				<div style="width: 100%; height: 100%; position: absolute; background-color: rgba(67, 67, 67, .5);">
				
					<v-row
						no-gutters
						justify="center"
					>
						<v-col 
							cols="12" sm="12" md="6" lg="6" xl="6"
							class="white--text pr-md-2 pr-lg-2 pr-xl-2"
						>
							<v-row
								no-gutters
								justify="end"
							>
								<v-col cols="12" sm="12" md="12" lg="11" xl="10"
									v-intersect="onIntersect"
									name="animate__animated animate__flipInX"
									class="mt-5 mt-sm-5 mt-md-10 mt-lg-10 mt-xl-10 text-center text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 elementHide"
								>{{ visionTitle }}</v-col>
								
								<v-col cols="12" sm="12" md="12" lg="11" xl="10"
									v-intersect="onIntersect"
									name="animate__animated animate__fadeIn"
									class="px-2 px-sm-2 px-md-0 px-lg-0 px-xl-0 font-weight-light text-center text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 elementHide"
								>{{ visionText }}</v-col>
								
								<v-col cols="12" sm="12" md="12" lg="11" xl="10"
									v-intersect="onIntersect"
									name="animate__animated animate__fadeIn"
									class="mt-5 mt-sm-5 mt-md-14 mt-lg-16 mt-xl-16 font-weight-bold text-center text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 elementHide"
								>
									<v-row
										no-gutters
										justify="center"
									>
										<v-col cols="auto">
											<font class="text-h3 text-sm-h3 text-md-h2 text-lg-h1 text-xl-h1" style="line-height:70%;">“</font>
										</v-col>
										
										<v-col cols="11" sm="11" md="10" lg="10" xl="11">
											{{ mottoText }}
											<br/>-- Capt. Felix G. Valenzona<br/>CEO/President
										</v-col>
										
										<v-col cols="auto">
											<font class="text-h3 text-sm-h3 text-md-h2 text-lg-h1 text-xl-h1" style="line-height:70%;">”</font>
										</v-col>
									</v-row>
								</v-col>
								
							</v-row>
						</v-col>
					
						<v-col cols="12" sm="12" md="6" lg="6" xl="6" class="white--text pl-md-2 pl-lg-2 pl-xl-2">
							<v-row
								no-gutters
								justify="start"
							>
								<v-col 
									cols="12" sm="12" md="12" lg="11" xl="10"
									v-intersect="onIntersect"
									name="animate__animated animate__flipInX"
									class="mt-5 mt-sm-5 mt-md-10 mt-lg-10 mt-xl-10 text-center text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 white--text font-weight-regular elementHide"
								>
									{{ portFolioTitle }}
								</v-col>
								
								<v-col 
									cols="12" sm="12" md="12" lg="11" xl="10"
									v-intersect="onIntersect"
									name="animate__animated animate__fadeIn"
									class="px-2 px-sm-2 px-md-0 px-lg-0 px-xl-0 font-weight-light text-center text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 elementHide"
								>
									{{ portFolioText }}
								</v-col>
								
								<v-col
									cols="12" sm="12" md="12" lg="11" xl="10"
									align-self="start"
									v-intersect="onIntersect"
									name="animate__animated animate__fadeIn"
									class="mt-md-2 mt-lg-2 mt-xl-2 text-body-1 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h4 text-center pageTitle elementHide"
								>
									<v-progress-circular
										:rotate="-90"
										:size="this.$store.getters.portFolioSize"
										:width="this.$store.getters.portFolioWidth"
										color="amber lighten-1"
										:value="value"
										class="mt-16"
									>
										<span class="white--text" style="line-height:90%;">{{ numberFormat(crewServed) }}+<br/>Crew<br/>Served</span>
									</v-progress-circular>
								
									<v-progress-circular
										v-intersect="eventFortPolio"
										:rotate="-35"
										:size="this.$store.getters.portFolioSize"
										:width="this.$store.getters.portFolioWidth"
										color="orange darken-2"
										:value="value"
										class="mb-8 mx-n6 mb-sm-9 mx-sm-n5 mb-md-10 mx-md-n5 mb-lg-16 mx-lg-n6 mb-xl-16 mx-xl-n6"
										style="z-index:2;"
									>
										<span class="white--text" style="line-height:90%;">{{ yearsInService }}<br/>Years<br/>Service</span>
									</v-progress-circular>
								
									<v-progress-circular
										:rotate="360"
										:size="this.$store.getters.portFolioSize"
										:width="this.$store.getters.portFolioWidth"
										color="light-green"
										:value="value"
										class="mt-16"
									>
										<span class="white--text" style="line-height:90%;">{{ vessels }}+<br/>Vessels Manned</span>
									</v-progress-circular>
								</v-col>
							</v-row>
						</v-col>
						
					</v-row>
					
					</div>
			</v-img>
			
		</v-card>
	</v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import 'animate.css';

var portFolioTimeout = '';

export default {
	name: 'App',
	data: () => ({
		value			: 0,
		interval		: {},
		yearsInService	: 0,
		vessels			: 0,
		crewServed		: 0,
		page			: 'About Us',
		path			: '/about',
		pageSubtitle	: 'We put all our plans into actions',
		aboutUsText		: 'Alster International Shipping Services, Inc. (AISSI) is existing as a duly registered company with the Securities and Exchange Commission (SEC) since 25 May 1988 and was officially issued a License to Operate as a Manning Agency by the Philippine Overseas Employment Administration (POEA) on 28 August 1988. The commitment for the quality service have proven  the good management practices of the company beaing an ISO certified company since 1997. With its profound experience strongly for twenty years, the results of hardships and dedication of all Alster personnel are evident. The company is ready to face the challenges over the following years embedding ourselves sincerely on the performance of our responsibilities and focus on our quality processes.',
		visionTitle		: 'OUR VISION',
		visionText		: "To be the best manning agency that provides quality services with real value to it's customers: with strengthened leaders: and top quality employees to become the industry's model for consistently profitable business growth.",
		portFolioTitle	: 'OUR PORTFOLIO',
		portFolioText	: 'Our company currently manned different type of vessel: bulk carrier, chemical/oil tanker and multipurpose vessels. With the strategically cooperative efforts of our principals we maintain the Cadetship Program on their vessels and helped in sustaining the continuity of well trained future officers and engineers.',
		mottoText		: 'We put all our plans into actions, we reflect and contribute for the improvement of our committed services for a mutual beneficial business releationship.',
	}),
	computed:{
		...mapGetters(['pageHeight', 'aboutUsImageH'])
	},
	methods: {
		pageViewIn: function(e){
			if(e[0].isIntersecting)if( this.$route.path != this.path )this.$router.push(this.path);
		},
		onIntersect: function(e){
			const effects = e[0].target.attributes['name'].value;
			
			if(e[0].isIntersecting ){
				e[0].target.className = e[0].target.className.replace('elementHide', effects);
			}else{
				e[0].target.className = e[0].target.className.replace(effects, 'elementHide');
			}
		},
		eventFortPolio: function(e){
			if(e[0].isIntersecting ){
				
				clearTimeout(portFolioTimeout);
				
				portFolioTimeout = setTimeout(() => {
					
					this.yearsInService = this.vessels = this.crewServed = this.value = 0;
					
					clearInterval(this.interval);
					this.interval = setInterval(() => {
						
						if( this.value < 100){
							this.value += 5;
							this.yearsInService = Math.round(((new Date().getFullYear() - 1988)/100)*this.value);
							this.vessels = Math.round((90/100)*this.value);
							this.crewServed = Math.round((10000/100)*this.value);
						}else{
							clearInterval(this.interval);
						}
					}, 10);
				}, 500);
			}else{
				clearTimeout(portFolioTimeout);
				
				this.interval = {};
				this.yearsInService = this.vessels = this.crewServed = this.value = 0;
			}
		},
		numberFormat: function(num){
			var num_parts = num.toString().split(".");
			num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return num_parts.join(".");
		}
	},
	beforeDestroy(){
		clearTimeout(portFolioTimeout);
		clearInterval(this.interval);
	},
};
</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .overflowHide{
		overflow : hidden;
	}
	
	>>> .pageTitle {
		letter-spacing:1px;
		font-weight:500;
		font-size:1.30rem;
    }
</style>