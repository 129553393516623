export const USER_RESIZE			= 'USER_RESIZE'
export const USER_CLEAR				= 'USER_CLEAR'
export const SET_DATE 				= 'SET_DATE'
export const STORE_INFO 			= 'STORE_INFO'

export const CLEAR_ALERT			= 'CLEAR_ALERT'

export const SUCCESS_CREATE			= 'SUCCESS_CREATE'
export const SUCCESS_UPDATE 		= 'SUCCESS_UPDATE'
export const SUCCESS_DELETE			= 'SUCCESS_DELETE'

export const ERROR_VIEW 			= 'ERROR_VIEW'

export const ERROR_CREATE_FORM 		= 'ERROR_CREATE_FORM'
export const ERROR_CREATE_SERVER	= 'ERROR_CREATE_SERVER'

export const ERROR_UPDATE_FORM 		= 'ERROR_UPDATE_FORM'
export const ERROR_UPDATE_SERVER	= 'ERROR_UPDATE_SERVER'

export const ERROR_DELETE_SERVER	= 'ERROR_DELETE_SERVER'

export const ERROR_NETWORK			= 'ERROR_NETWORK'

export const UPLOAD_PROFILE_OPEN 	= 'UPLOAD_PROFILE_OPEN'
export const UPLOAD_PROFILE_CLOSE	= 'UPLOAD_PROFILE_CLOSE'
export const IMAGE_PROFILE_LOAD		= 'IMAGE_PROFILE_LOAD'