import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

import { AUTH_UNAUTHORIZED, AUTH_ADMIN } from './store/actions/auth'
import { ERROR_VIEW, ERROR_NETWORK } from './store/actions/user'
import { SUCCESS_CREATE, SUCCESS_UPDATE, SUCCESS_DELETE } from './store/actions/user'
import { ERROR_CREATE_FORM, ERROR_CREATE_SERVER, ERROR_UPDATE_FORM, ERROR_UPDATE_SERVER, ERROR_DELETE_SERVER } from './store/actions/user'

const token = localStorage.getItem('user-token')
if (token) { //set uthorization token for every request
	axios.defaults.headers.common['Authorization'] = 'Bearer ' +token;
	
	const admin = localStorage.getItem('user-admin')
	if(admin){
		store.dispatch(AUTH_ADMIN);
	}
}

//set baseurl for every request
//axios.defaults.baseURL = 'http://192.168.125.121:8000/api';
//axios.defaults.baseURL = 'http://alsterph-env-1.eba-kkz9qpyb.ap-southeast-1.elasticbeanstalk.com/api';
axios.defaults.baseURL = 'https://api.alsterph.com/api';

axios.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
	if( localStorage.getItem('user-token') ){
		
		const msg = response.data.msg;
		if(response.status === 200 && response.config.method == 'put'){
			store.dispatch(SUCCESS_UPDATE, {msg});
		}else if(response.status === 200 && response.config.method == 'delete'){
			store.dispatch(SUCCESS_DELETE, {msg});
		}else if(response.status === 201){
			store.dispatch(SUCCESS_CREATE, {msg});
		}
	}
	
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
	//if(typeof error.response !== 'undefined'){
	if(error.response){
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		const msg = error.response.data.msg;
		
		if(error.response.status === 401){
			store.dispatch(AUTH_UNAUTHORIZED).then(() => {
				if( router.currentRoute.name != '' )router.push('/');
			});
		}else if( localStorage.getItem('user-token') ){
			if(error.response.status === 400){
				if(error.response.config.method == 'post'){
					store.dispatch(ERROR_CREATE_FORM, {msg});
				}else if(error.response.config.method == 'put'){
					store.dispatch(ERROR_UPDATE_FORM, {msg});
				}
			}else if(error.response.status === 500){
				if(error.response.config.method == 'post'){
					store.dispatch(ERROR_CREATE_SERVER, {msg});
				}else if(error.response.config.method == 'put'){
					store.dispatch(ERROR_UPDATE_SERVER, {msg});
				}else if(error.response.config.method == 'delete'){
					store.dispatch(ERROR_DELETE_SERVER, {msg});
				}else if(error.response.data.action == 'get'){
					store.dispatch(ERROR_VIEW, {msg});
				}
			}
			
		}
		
	}else if(error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		if( localStorage.getItem('user-token')
			&& error.config.url != 'api/profile/crewInfo'
			&& error.config.url.slice(0, 12) != 'api/dropdown'
			&& error.config.url.slice(0, 12) != 'api/register'
			&& error.config.url.slice(0, 16) != 'api/autocomplete'
		)store.dispatch(ERROR_NETWORK);
	}else{
		// Something happened in setting up the request that triggered an Error
		if( localStorage.getItem('user-token') )store.dispatch(ERROR_NETWORK);
	}
	
	return Promise.reject(error);
});

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.use(VueTour)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')