<template>
	<v-container fluid class="px-0 mx-0">
		<v-card
			elevation="0"
			v-intersect="pageViewIn"
			:min-height="this.$store.getters.pageHeight"
			class="ma-0 pa-0"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
				class="mt-16"
			>
				<v-col
					cols="12"
					class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text-center text-uppercase font-weight-regular"
					v-bind:class="{ animate__animated : pageActive, animate__slideInDown : pageActive, elementHide : !pageActive }"
				>
					{{ page }}
				</v-col>
				
				<v-col
					cols="11" sm="11" md="10" lg="9" xl="8"
					class="mb-5 text-center font-weight-light text-uppercase text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6"
					v-bind:class="{ animate__animated : pageActive, animate__slideInUp : pageActive, elementHide : !pageActive }"
					style="overflow : hidden;"
				>
					{{ pageSubtitle }}
				</v-col>
				
			</v-row>
			
			<v-row 
				align="center"
				justify="center"
				no-gutters
				style="overflow : hidden;"
			>
				<v-col
					cols="12" sm="12" md="12" lg="12" xl="11"
					v-bind:class="{ animate__animated : pageActive, animate__fadeIn : pageActive, elementHide : !pageActive }"
				>
					<v-slide-group
						v-model="training"
						center-active
						prev-icon="mdi-arrow-left-circle"
						next-icon="mdi-arrow-right-circle"
						@click:prev="training--"
						@click:next="training++"
					>
						<v-slide-item
							v-for="details in trainingDetails"
							:key="details.title"
							v-slot:default="{ active }"
							class="rounded-0"
						>
							<v-hover v-slot:default="{ hover }">
								<v-card elevation="0" :width="trainingImageW" @click="trainingPause(details);" :height="trainingWindowH + 2" :min-height="( (trainingImageW/100)*120 ) + 2" outlined>
									<v-img
										class="d-flex"
										position="top right"
										:src="require(`@/assets/images/training/${details.image}`)"
										:height="(trainingImageW/100)*60"
										
									>
										<div
											class="d-flex transition-fast-in-fast-out imagesOverlayFull"
											:class="{ 'imagesOverlayFullHover': hover || active }"
										>
										</div>
										
										<div class="d-flex white--text imagesOverlay">
											<p class="text-uppercase text-center text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 px-1 pt-5" style="line-height:100%;">
												{{ details.title }}
											</p>
										</div>
									</v-img>
									
									<v-card elevation="0"
										:class="{ 'on-hover': hover || active }"
										class="rounded-0 trainingImage"
										:width="trainingImageW"
										:height="trainingWindowH - (trainingImageW/100)*60"
										:min-height="(trainingImageW/100)*60"
										style="overflow-y: auto;"
									>
										<v-card-text>
											<span class="text-body-2 font-weight-bold">{{ details.subtitle }}</span>
											<p class="py-2 my-0 text-body-2 font-weight-bold">OBJECTIVES:</p>
											<span class="text-body-2 font-weight-light trainingObjectives" v-html="details.description"></span>
										</v-card-text>
										
									</v-card>
								</v-card>
							</v-hover>
							
						</v-slide-item>
					</v-slide-group>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>

import { mapGetters } from 'vuex';

var trainingInterval = '';
var trainingTimeout = '';

export default {
	data: () => ({
		training	: 2,
		pageActive	: false,
		page		: 'Training',
		path		: '/training',
		pageSubtitle: 'REAL WORLD TRAINING FOR REAL-WORLD RESULTS',
		trainingDetails: [
			{
				title: 'VOYAGE PLANNING',
				image: 'VoyagePlanning.jpg',
				subtitle: 'Monitoring progress and comparing it to the plan are key to voyage planning.',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Determine the factors and requirements for the preparation of passage plan.</li><li>Develop, evaluate and approve the passage plan using the forms and other related publications.</li><li>Implement passage plan in a simulated scenario and perform actions in accordance to the procedures on the SMS in compliance to ISM.</li></ol>'
			},
			{
				title: 'Control Engineering',
				image: 'ControlEngineering.jpg',
				subtitle: 'This course is intended to provide marine engineers and ratings forming part of an engine watch about the knowledge and competence required by AISSI in accordance to the requirements of its principals.',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Demonstrate the working principle and concept of control engineering.</li><li>Knowledge, understanding and proficiency on application, installation, and construction of control engineering aboard ships.</li><li>Determine and familiarize with the basic approach in troubleshooting common equipment breakdown and simulated hands-on practice.</li></ol>'
			},
			{
				title: 'Hydraulics/Pneumatic',
				image: 'HydraulicsPneumatic.jpg',
				subtitle: 'This course is intended to provide marine engineers and ratings forming a part of an engineering watch about the knowledge and competence required by AISSI in accordance with the quality and safety management systems of its principals on the aspect of hydraulic and pneumatic systems.',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Contribute to the safe working practices and occupational hazards during the course of laboratory exercises.</li><li>Have adequate knowledge of application, installation and construction of pneumatics and hydraulics systems aboard the ship.</li><li>Interpret circuit diagrams and work in conjunction with engineering drawings.</li><ol>'
			},
			{
				title: 'Ship Security Awareness',
				image: 'ShipSecurityAwareness.jpg',
				subtitle: 'All personnel assigned to specific duties in accordance with the security plan and to all personnel involved in securing the vessels in compliance to the requirements of chapter XI-2 of SOLAS 74 as amended and the ISPS code.',
				description: 'Seafarers who completed this course should be able to undertake duties and responsibilities of ship security personnel according to section A/13 of the ISPS code by the following course objectives:<br/><ol><li>Introduce techniques and skills required to address qualifications of ship security personnel.</li><li>Provide general concept of ISPS code and relevant legislation, security threats and patterns with consideration of people who likely to threaten security.</li><li>Provide awareness on detection of weapons, dangerous substances and devices.</li><li>Ability to undertake security related communications, emergency procedures and contingency planning.</li><ol>'
			},
			{
				title: 'Computer Based Training',
				image: 'ComputerBasedTraining.jpg',
				subtitle: 'Our computer-based training courses provide engaging, interactive features that enhance our crew learning experience.',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Gain knowledge and understanding with a training as a reinforcement to the specific topic indicated weak on the evaluation report after taking the CES Test.</li><li>Determine if the seafarer after being reinforced with the training, should be recommended for further or other modes or specific training on the weak points.</li><ol>'
			},
			{
				title: 'Tanker Safety and Vetting',
				image: 'TankerSafetyAndVetting.jpg',
				subtitle: 'COURSE DESCRIPTION',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Define the principles of ship vetting inspection process in relation to the requirements of the ISM code.</li><li>Identify the applicable IMO and maritime legislations.</li><li>Understand the principles of system auditing, adopt and implement techniques and strategies of inspection.</li><li>Carry out a table top simulation exercise using relevant forms and document.</li><ol>'
			},
			{
				title: 'Computer Troubleshooting',
				image: 'ComputerTroubleshooting.jpg',
				subtitle: 'This course is intended to provide deck officers and Marine Engineers the basic knowledge of computer troubleshooting, perform practical troubleshooting and analysis of computer problems and gain practical experience of handling the hardware and software systems of a computer.',
				description: '<ol><li>To gain basic troubleshooting skills and preventive maintenance for optimal computer performance.</li><li>To identify hardware components and their basic function.</li><li>Gain the necessary knowledge to successfully assemble and disassemble a computer.</li><ol>'
			},
			{
				title: 'Basic Safety Familiarization',
				image: 'BasicComputerFamiliarization.jpg',
				subtitle: 'Our basic safety training – familiarization, prevent, control and fight fires on board, operate life saving appliances, apply medical first aid on board ship and personal safety and social responsibility.',
				description: 'Seafarers who completed this course will be able to:'
			},
			{
				title: 'Maritime Electro-Technology',
				image: 'MaritimeElectroTechnology.jpg',
				subtitle: 'Skilled personnel help increase the availability, rate, and quality of your vessels and boost overall equipment effectiveness.',
				description: 'Seafarers who completed this course will be able to demonstrate the working principle of electrical technology through practical exercises.	<br/>The knowledge shall include but not limited to:<br/><ol><li>The awareness of safe working practices and occupational hazards prior and during the course of laboratory exercises.</li><li>Application, installation and troubleshooting of electrical tools and equipment aboard ships.</li><li>Interpret circuit diagrams and work in conjunction with engineering drawings.</li><ol>'
			},
			{
				title: 'Basic Computer Familiarization',
				image: 'BasicSafetyFamiliarization.jpg',
				subtitle: 'This course is designed to improve understanding of how computers work and familiarize with basic computer applications.',
				description: 'Seafarers who completed this course will be able to:<br/><ol><li>Create documents, spreadsheets, and other files necessary for on board recording and reporting activities.</li><li>Be capable of optimizing the use of computers by creating their own spreadsheet formats as efficient alternative to manual systems.</li><ol>'
			},
			{
				title: 'International Safety Management',
				image: 'InternationalSafetyManagement.jpg',
				subtitle: 'We provide A framework for the proper development, implementation and assessment of safety and pollution prevention management in accordance with good practice. ',
				description: 'Seafarers who completed this course will be able to define the ISM code and will be able to define the ISM Code and discuss the basic theories and practical requirements for the proper implementation, monitoring, analysis and evaluation of the Safety Management Systems (SMS).	<br/>Emphasis of the course will be on the functional requirements of the ISM code and how seafarers would get into the system of compliance by the performance of their tasks on board ship.'
			},
			{
				title: 'Global Maritime Distress and Safety System',
				image: 'GlobalMaritime_DistressAndSafetySystem.jpg',
				subtitle: 'We believe that hands-on training with real world examples is the key to learning and retention.',
				description: 'Seafarers who completed this course will be able to:'
			},
		]
	}),
	computed:{
		...mapGetters(['trainingWindowW', 'trainingImageW', 'trainingWindowH'])
	},
	methods: {
		pageViewIn: function(e){
			if(e[0].isIntersecting){
				if( this.$route.path != this.path )this.$router.push(this.path);
				this.trainingLoop();
				this.pageActive = true;
			}else{
				this.pageActive = false;
				clearInterval(trainingInterval);
			}
		},
		trainingPause (el){
			this.training = this.trainingDetails.indexOf(el);
			
			clearInterval(trainingInterval);
			clearTimeout(trainingTimeout);
			
			trainingTimeout = setTimeout(() => {
				this.trainingLoop();
			}, 9000);
		},
		trainingLoop () {
			clearInterval(trainingInterval);
			
			trainingInterval = setInterval( () => {
				if (++this.training >= this.trainingDetails.length) this.training = 0;
			}, 6000);
		}
	},
	beforeDestroy (){
		clearTimeout(trainingTimeout);
		clearInterval(trainingInterval);
	},
}
</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .trainingImage {
		transition: opacity .4s ease-in-out;
	}

	>>> .trainingImage:not(.on-hover) {
		opacity: 0.7;
	}
	
	>>> .imagesOverlayFull{
		top: 0;
		position: absolute;
		outline: 1px black;
		width: 100%;
		height:65%;
		background-color: rgba(67, 67, 67, .5);
	}
	
	>>> .imagesOverlayFullHover{
		height:0%;
	}
	
	>>> .imagesOverlay {
		align-items: center;
		bottom: 0;
		justify-content: center;
		position: absolute;
		outline: 1px black;
		width: 100%;
		height:35%;
		background-color: rgba(67, 67, 67, .5);
	}
	
	>>> .trainingObjectives ol{
		padding-left:15px;
	}
</style>