<template>
	<v-container
		fluid
		class="pa-0 ma-0"
		v-intersect="pageViewIn"
	>
		<v-row
			no-gutters
			class="pt-12"
			style="min-height:100vh; display: flex; flex-wrap: wrap; align-content:space-between;"
		>
			<v-col cols="12">
				<v-row
					no-gutters
					align="center"
					justify="center"
				>
					<v-col cols="12" sm="10" md="6" lg="6" xl="5" class="mt-10 mt-sm-12 mt-md-7 mt-lg-7 mt-xl-7">
						<v-card
							color="grey" :height="$vuetify.breakpoint.smAndDown? '85vh' : 560" width="100%" elevation="0" outlined
							name="animate__animated animate__fadeIn"
							class="text-h3 elementHide mt-n5 mx-0 mr-md-5 mr-lg-5 mr-xl-5"
							v-intersect="onIntersect"
						>
							<iframe 
								width="100%"
								height="100%"
								frameborder="0"
								scrolling="no"
								marginheight="0"
								marginwidth="0"
								id="gmap_canvas"
								src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Alster%20International%20Shipping%20Services,%20Inc%20Makati%20City+(Alsterph)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
							>
							</iframe>
						</v-card>
					</v-col>
					
					<v-col cols="11" sm="10" md="6" lg="6" xl="5">
						<v-form
							lazy-validation
							v-model="contactUsValid"
							ref="contactUsForm"
						>
							<v-col class="text-h5 elementHide" name="animate__animated animate__fadeIn" v-intersect="onIntersect">
								MAIL US YOUR MESSAGE
							</v-col>
							
							<v-col
								name="animate__animated animate__fadeIn"
								v-intersect="onIntersect"
								class="pb-0 elementHide"
							>
								<v-text-field
									v-model="name"
									label="Name"
									dense
									tile
									outlined
									:rules="nameRules"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col
								name="animate__animated animate__fadeIn"
								v-intersect="onIntersect"
								class="pb-0 elementHide"
							>
								<v-text-field
									v-model="email"
									label="Email"
									dense
									tile
									outlined
									:rules="emailRules"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col
								name="animate__animated animate__fadeIn"
								v-intersect="onIntersect"
								class="pb-0 elementHide"
							>
								<v-select
									:items="subjectItems"
									v-model="subject"
									label="Subject"
									dense
									tile
									outlined
									:rules="[v => !!v || 'Subject is required']"
								></v-select>
							</v-col>
							
							<v-col
								name="animate__animated animate__fadeIn"
								v-intersect="onIntersect"
								class="pb-0 elementHide"
							>
								<v-textarea
									v-model="message"
									label="Message"
									clearable
									dense
									tile
									outlined
									:rules="messageRules"
									validate-on-blur
								></v-textarea>
							</v-col>
							
							<v-col
								class="pt-0 text-caption font-weight-light elementHide"
								v-intersect="onIntersect"
								name="animate__animated animate__fadeIn"
							>
								Note: All fields are required
							</v-col>
							
							<v-col
								class="elementHide"
								v-intersect="onIntersect"
								name="animate__animated animate__fadeIn"
							>
								<v-btn
									color="primary"
									:disabled="!contactUsValid"
									large
									:loading="sendLoading"
									outlined
									class="mr-4"
									@click="sendMessage"
								>
									SEND MESSAGE
								</v-btn>
							</v-col>
						</v-form>
					</v-col>
					
				</v-row>
			</v-col>
			
			<v-col cols="12">
				<Footer/>
				
				<v-snackbar
					v-model="snackbar"
					:color="snackbarColor"
					bottom
					class="text-h3 font-weight-bold"
				>
					<v-icon dark small class="pr-2">mdi-check-circle</v-icon>{{ snackbarMsg }}
					<template v-slot:action="{ attrs }">
						<v-icon small v-bind="attrs" @click="snackbar = false">mdi-close-circle</v-icon>
					</template>
				</v-snackbar>
			</v-col>
		</v-row>
	</v-container>
</template>

<script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=37b8d31c6c707aaefbc4ef576d93f39d1a128a4d'></script>

<script>

import Footer from './../publicLayout/Footer.vue'

export default {
	components: {
		Footer
	},
	data: () => ({
		page			: 'Contact Us',
		path			: '/contact',
		snackbarColor	: 'success',
		contactUsValid	: true,
		sendLoading		: false,
		snackbar		: false,
		snackbarMsg		: '',
		name			: '',
		email			: '',
		message			: '',
		subject			: '',
		subjectItems	: [
			'Apply for Cadet',
			'Official Matter',
			'Business Matter',
			'Other Matters' 
		],
		nameRules: [
			v => !!v || 'Name is required',
			v => (v && v.length >= 2) || 'Name must be atleast 2 characters',
		],
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		messageRules: [
			v => !!v || 'Message is required',
			v => (v && v.length >= 20) || 'Message must be atleast 20 characters',
		],
	}),
	methods: {
		pageViewIn: function(e){
			if(e[0].isIntersecting)if( this.$route.path != this.path )this.$router.push(this.path);
		},
		reset () {
			this.$refs.contactUsForm.reset();
		},
		sendMessage() {
			if( this.$refs.contactUsForm.validate() ){
				this.sendLoading = true;
				
				this.$http.post('web/contactUs', {
					name	: this.name,
					email	: this.email,
					subject	: this.subject,
					message	: this.message,
				})
				.then(() => {
					this.reset();
					this.contactUsValid = true;
					this.snackbarColor = 'success';
					this.snackbarMsg = 'MESSAGE SENT';
				})
				.catch(() => {
					this.snackbarColor = 'error';
					this.snackbarMsg = 'SENDING FAILED';
				})
				.finally(() => {
					this.snackbar = true;
					this.sendLoading = false;
				});
			}
		},
		onIntersect: function(e) {
			const effects = e[0].target.attributes['name'].value;
			
			if(e[0].isIntersecting ){
				e[0].target.className = e[0].target.className.replace('elementHide', effects);
			}else{
				e[0].target.className = e[0].target.className.replace(effects, 'elementHide');
			}
		}
	},
	mounted() {
		this.reset(); 
	},
}
</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
</style>