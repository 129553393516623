<template>
	<!-- App.vue -->
	<v-app v-resize="onUserResize">
		<v-app-bar
			elevation= "0"
			app
			ref="userAppBar"
			dense
			class="ml-n1 mr-n3"
		>
			<v-tooltip bottom>
				<template v-slot:activator="{ on: leftMenu }">
					<v-btn
						v-on="leftMenu"
						small
						depressed
						fab
						@click.stop="toggleNavDrawer()"
						id="v-page-step-1"
					>
						<v-icon>{{ navIcon }}</v-icon>
					</v-btn>
				</template>
				<span>Toggle</span>
			</v-tooltip>
			
			<v-toolbar-title class="text-h6 font-weight-regular">{{ routeName }}</v-toolbar-title>
			
			<v-spacer></v-spacer>
			
			<v-menu offset-y>
				
				<template v-slot:activator="{ on: rightMenu, attrs }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								small
								depressed
								class="mr-n1 mr-sm-3 mr-md-3 mr-lg-3 mr-xl-3"
								v-bind="attrs"
								v-on="{ ...tooltip, ...rightMenu }"
								id="v-page-step-2"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<span>Menu</span>
					</v-tooltip>
				</template>
				
				<v-list dense>
					<v-list-item @click="accountSettings">
						<v-list-item-icon>
							<v-icon>mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
						<v-list-item-title>ACCOUNT</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
						<v-list-item-title>LOG OUT</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				
			</v-menu>
				
		</v-app-bar>
		
		<v-navigation-drawer
			app
			v-model="navDrawer2"
			:mini-variant.sync="navMini"
			:permanent="navPermanent"
			:expand-on-hover="navHover"
			color="primary"
			dark
		>
			<v-list-item dark class="pl-2">
				<v-list-item-avatar tile>
					<v-icon>mdi-plus</v-icon>
				</v-list-item-avatar>
				<v-list-item-title>&nbsp;</v-list-item-title>
			</v-list-item>
		</v-navigation-drawer>
		
		<v-navigation-drawer
			v-model="navDrawer"
			:mini-variant.sync="navMini"
			:permanent="navPermanent"
			:expand-on-hover="navHover"
			color="primary"
			dark
			absolute
			style="z-index:10;"
		>
			<v-list-item dark dense class="pl-2">
				<v-list-item-avatar tile class="my-1 py-1">
					<v-img height="40" :src="require('@/assets/images/logo/alsterlogowhiteIcon.png')"></v-img>
				</v-list-item-avatar>
				<v-list-item-title class="text-body-1"><strong>ALSTERPH</strong></v-list-item-title>
			</v-list-item>
			
			<v-divider></v-divider>
			
			<v-list-item class="pl-2">
				<v-list-item-avatar>
					<v-img :src="imageProfile"></v-img>
				</v-list-item-avatar>
				
				<v-list-item-title>
					{{ fname }} 
					<p style="line-height:9px;" class="mb-0 overline font-weight-medium">{{ rank }}</p>
				</v-list-item-title>
			</v-list-item>

			<v-divider></v-divider>
			
			<v-list dense ref="userMenuOption" id="v-page-step-3">
				<template v-for="item in mainMenu">
					<v-list-item
						v-if="!item.subGroup"
						link
						:key="item.title"
						:to="item.path == '' ? '' : item.path"
						:exact=true
					>
						<v-list-item-icon class="mr-6">
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>

					<v-list-group
						v-else
						:key="item.title"
						active-class="white--text"
						no-action
						:value="subGroupActive(item.title)"
					>
						<template v-slot:activator>
							<v-list-item-icon class="mr-6">
								<v-icon>{{ item.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</template>
						
						<v-list-item
							v-for="subItem in item.subMenu"
							link
							:key="subItem.title"
							:to="subItem.path == '' ? '' : subItem.path"
							:exact=true
							active-class="white--text"
						>
							<v-list-item-title>{{ subItem.title }}</v-list-item-title>
							<v-list-item-icon>
								<v-icon>{{ subItem.icon }}</v-icon>
							</v-list-item-icon>
						</v-list-item>
					</v-list-group>
				</template>
			</v-list>

		</v-navigation-drawer>
		
		<!-- Sizes your content based upon application components -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<v-container fluid class="mt-0 pt-0 pr-0 mr-0 pb-0 mb-0">
			
				<!-- If using vue-router -->
				<router-view></router-view>
				
			</v-container>
		</v-main>
		
		<uploadProfile/>
		
		<welcomeMessage/>
		
		<v-tour name="vTourPage" :steps="vTourSteps" :options="vTourOptions"></v-tour>
		
		
		
	</v-app>
</template>

<script>

import { mapGetters } from 'vuex';

import { AUTH_LOGOUT } from '@/store/actions/auth';
import { STORE_INFO, USER_RESIZE, USER_CLEAR, SET_DATE, IMAGE_PROFILE_LOAD } from '@/store/actions/user';

const uploadProfile = () => import('@/components/User/uploadProfile')
const welcomeMessage = () => import('@/components/User/welcomeMessage')

var breakpointName	= '';
var timeOutNav		= null;
var timeoutData		= null;

export default {
	components: {
		uploadProfile,
		welcomeMessage,
	},
	data () {
		return {
			snackBar: true,
			windowWidth	: 0,
			windowHeight: 0,
			navUser		: false,
			navMini		: false,
			navHover	: false,
			navDrawer	: null,
			navDrawer2	: null,
			navPermanent: false,
			appBarHeight: 0,
			mainMenu: [
				{ title: 'DASHBOARD',				icon: 'mdi-view-dashboard',		path: '/' ,			subGroup: false },
				{ title: 'PROFILE SUMMARY', 		icon: 'mdi-account-details',	path: '/summary',	subGroup: false },
				{ title: 'PERSONAL INFO',			icon: 'mdi-account-box-multiple',					subGroup: true,
					subMenu: [
						{ title: 'PERSONAL',		icon: 'mdi-file-account',		path: '/basicinfo' },
						{ title: 'CONTACT',			icon: 'mdi-home-map-marker',	path: '/contactinfo' },
						{ title: 'EDUCATION',		icon: 'mdi-school',				path: '/education' },
						{ title: 'FAMILY',			icon: 'mdi-account-group',		path: '/family' },
						{ title: 'QUESTIONNAIRE',	icon: 'mdi-head-question',		path: '/otherinfo' },
					]
				},
				{ title: 'EMPLOYMENT',				icon: 'mdi-ferry',				path: '/employment',subGroup: false },
				{ title: 'DOCUMENTS',				icon: 'mdi-file-document-multiple',					subGroup: true,
					subMenu: [
						{ title: 'LOCAL',			icon: 'mdi-file',				path: '/localdocument' },
						{ title: 'FOREIGN',			icon: 'mdi-file-document',		path: '/foreigndocument' },
						{ title: 'TRAININGS',		icon: 'mdi-file-check',			path: '/trainings' },
						{ title: 'CERTIFICATES',	icon: 'mdi-file-cad-box',		path: '/certificate' },
						{ title: 'VISA',			icon: 'mdi-file-cloud',			path: '/visa' },
					]
				},
				{ title: 'SETTINGS',				icon: 'mdi-account-cog',							subGroup: true,
					subMenu: [
						{ title: 'ACCOUNT',			icon: 'mdi-account',			path: '/account' },
						{ title: 'LOG OUT',			icon: 'mdi-logout',				path: '/logout' },
					]
				},
				{ title: 'HELP CENTER',				icon: 'mdi-frequently-asked-questions',			path: '/helpcenter',subGroup: false }
			],
			
			vTourOptions: {
				labels: {
					buttonSkip: 'Skip tour',
					buttonPrevious: 'Previous',
					buttonNext: 'Next',
					buttonStop: 'Next'
				}
			},
			
			vTourSteps: [
				{
					target: '#v-page-step-1',
					header: { title: 'Toogle Menu' },
					content: 'Minimize/Hide/Show main menu'
				},
				{
					target: '#v-page-step-2',
					header: { title: 'Account Menu' },
					content: 'Quick access to Account settings'
				},
				{
					target: '#v-page-step-3',
					header: { title: 'Main Menu' },
					content: 'Navigate to access all sections here',
					params: {
						placement: 'left',
						modifiers: {
							preventOverflow: {
								enabled: true,
								boundariesElement: 'window',
							}
						}
					}
				}
			],
		}
    },
	methods: {
		pageTourEnd(){
			switch(this.$route.path){
				case '' || '/':
					this.$tours['vTourDashboard'].start();
				break;
				default:
					console.log('test');
				break;
			}
		},
		onUserResize () {
			
			if( this.windowWidth != window.innerWidth || this.windowHeight != window.innerHeight ){
			
				if( this.windowWidth != window.innerWidth ){
					if(window.innerWidth <= 600){
						breakpointName = 'xs';
					}else if(window.innerWidth > 600 && window.innerWidth <= 960){
						breakpointName = 'sm';
					}else{
						breakpointName = 'mdAndUp';
					}
					
					switch(breakpointName){
						case 'xs':
							this.navPermanent = false;
							this.navDrawer = false;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								
								this.navMini = this.navHover = false;
								
							}, 5);
						break;
						case 'sm':
							this.navMini = this.navHover = true;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								this.navPermanent = true;
							}, 5);
						break;
						case 'mdAndUp':
							if( !this.navUser )this.navMini = this.navHover = false;
							
							clearTimeout(timeOutNav);
							timeOutNav = setTimeout(() => {
								this.navPermanent = true;
							}, 5);
						break;
					}
				}
				
				this.windowWidth = window.innerWidth;
				this.windowHeight = window.innerHeight
				this.appBarHeight = this.$refs.userAppBar.$el.clientHeight;
				let { appBarHeight } = this;
				this.$store.dispatch(USER_RESIZE, { appBarHeight });
			}
			
		},
		accountSettings(){
			if( this.$route.path != '/account' )this.$router.push('/account');
		},
		logout(){
			this.$store.dispatch(USER_CLEAR);
			this.$store.dispatch(AUTH_LOGOUT).then(() => {
				if( this.$route.name != '' )this.$router.push('/');
			});
		},
		toggleNavDrawer(){
			if( !this.navDrawer )this.navDrawer = !this.navDrawer;
			this.navHover = this.navMini = (this.$vuetify.breakpoint.xsOnly)? false : !this.navMini;
			this.navUser = !this.navUser;
		},
		getCrewInfo () {
			//Set initial date base on user device
			const d = new Date()
			var date = (d.getFullYear() - 0 )+d.toISOString().substr(4, 6);
			this.$store.dispatch(SET_DATE, {date} );
			
			clearTimeout(timeoutData);
			
			this.$http.get('profile/name')
			.then((resp) => {
				//Set date base on server
				date = resp.data.date;
				const rank = resp.data.rank;
				const name = resp.data.fullName;
				this.$store.dispatch(SET_DATE, {date} );
				this.$store.dispatch(STORE_INFO, {rank, name} );
			})
			.catch(() => {
				//retry
				timeoutData = setTimeout(() => {
					this.getCrewInfo();
				}, 10000);
			});
		},
		subGroupActive(menu){
			if( menu == 'DOCUMENTS' ){  
				return ( this.$route.path == '/localdocument' || this.$route.path == '/foreigndocument' || this.$route.path == '/trainings' || this.$route.path == '/certificate' || this.$route.path == '/visa' )? true : false;
			}else if( menu == 'SETTINGS' ){
				return( this.$route.path == '/account' )? true : false;
			}else{
				return( this.$route.path == '/basicinfo' || this.$route.path == '/contactinfo' || this.$route.path == '/education' || this.$route.path == '/family' || this.$route.path == '/otherinfo' )? true : false;
			}
		}
	},
	computed: {
		...mapGetters(['imageProfile', 'rank', 'fname']),
		routeName () {
			return this.$route.name == '' ? 'DASHBOARD' : this.$route.name;
		},
		navIcon () {
			return this.navMini || this.$vuetify.breakpoint.xsOnly ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical';
		},
	},
	created () {
		// fetch the data when the view is created and the data is
		// already being observed
		this.getCrewInfo();
		this.$store.dispatch(IMAGE_PROFILE_LOAD);
	},
	mounted(){
		document.documentElement.style.overflowY  = "hidden";
	},
	beforeDestroy(){
		clearTimeout(timeOutNav);
		clearTimeout(timeoutData);
	},
}
</script>

<style scoped>
	>>> .v-tooltip__content{
		padding: 3px 16px;
	}
</style>