<template>
	<v-app-bar
		ref="refAppBar"
		dense
		elevation="0"
		:color="$route.path == '/'? 'transparent' : 'blue darken-4'"
		style="position:sticky; top: 0; z-index: 5"
		v-intersect.once="onIntersect"
	>
		<v-row no-gutters class="hidden-md-and-up">
			<v-col cols="5" ref="appBarLogo2" class="elementHide">
				<v-img
					:src="require('@/assets/images/logo/alsterlogowhite.png')"
					contain
					:height="logoHeight"
					:width="logoWidth"
					class="mt-1"
					alt="logo"
				>
				</v-img>
			</v-col>
			
			<v-col cols="2" class="text-center elementHide" ref="appBarMenuIcon">
				<v-icon dark ref="menuIconWheel" large 
					@click="drawer = !drawer"
					@mouseover="hover = true"
					@mouseleave="hover = false"
					:class="{ rotateMenu: hover }"
				>
					mdi-ship-wheel
				</v-icon>
			</v-col>
			
			<v-col cols="5" class="text-right elementHide" ref="appBarLogin2">
				<v-btn text class="white--text text-overline rounded-pill" @click="login" style="line-height:100%;">JOIN<br/>ALSTER</v-btn>
			</v-col>
		</v-row>
		
		<span class="elementHide" ref="appBarLogo">
			<v-img
				:src="require('@/assets/images/logo/alsterlogowhite.png')"
				contain
				:height="logoHeight"
				:width="logoWidth"
				class="mt-1 hidden-sm-and-down"
				alt="logo"
			>
			</v-img>
		</span>
		
		<v-tabs
			v-model="tab"
			ref="appBarMenuTab"
			centered
			dark
			slider-color="white"
			class="hidden-sm-and-down text-center mx-auto elementHide"
		>
			<v-tab
				v-for="menu in tabMenu"
				:to="menu.route"
				:key="menu.route"
				color="transparent"
				@click="tabClick(menu.title)"
				class="text-uppercase font-weight-bold text-md-caption text-lg-body-1 text-xl-body-1"
			>
				{{ menu.title }}
			</v-tab>
		</v-tabs>
		
		<span class="elementHide" ref="appBarLogin">
			<v-btn text
				class="white--text text-overline hidden-sm-and-down rounded-pill"
				style="line-height:100%;"
				@click="login"
			>JOIN<br/>ALSTER</v-btn>
		</span>
		
		<div @click="drawer = !drawer" id="overlay" v-show="drawer" transition="slide-y-transition" class="hidden-md-and-up">
			<v-list
				dense
				nav
				tile
				color="primary"
				class="text-center pa-0"
			>
				<v-list-item
					class="ma-0"
					v-for="menu in tabMenu"
					:key="menu.route"
					:to="menu.route"
					@click="tabClick(menu.title)"
					link
					dark
				>
					<v-list-item-title class="text-uppercase">{{ menu.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</div>
		
	</v-app-bar>
</template>

<script>

import { mapGetters } from 'vuex';

import { LOGIN_OPEN } from '@/store/actions/auth';

var timeoutMenu			= null;
var timeoutAppBar		= null;
var timeoutAppLogo		= null;
var timeoutAppLogin		= null;
var timeoutRouteName	= null;
	
export default {
	name: 'HEADER',
	data() {
		return {
			hover	: false,
			drawer	: false,
			tab		: null,
			tabMenu	: [
				{ title: 'Home',		route : '/' },
				{ title: 'About Us',	route : '/about' },
				{ title: 'Services',	route : '/services' },
				{ title: 'Training',	route : '/training' },
				{ title: 'News',		route : '/news' },
				{ title: 'Gallery',		route : '/gallery' },
				{ title: 'Contact Us',	route : '/contact' }
			],
		}
	},
	mounted () {
		if( this.$route.name != '')timeoutRouteName = setTimeout(() => { this.tabClick(this.$route.name); }, 10);
	},
	methods: {
		login: function(){
			this.$store.dispatch(LOGIN_OPEN);
		},
		onIntersect: function(e) {
			
			if(e[0].isIntersecting ){
				
				clearTimeout(timeoutAppBar);
				timeoutAppBar = setTimeout( () => {
					this.$refs.appBarMenuTab.$el.className = this.$refs.appBarMenuTab.$el.className.replace('elementHide', 'animate__animated animate__slideInDown');
					this.$refs.appBarMenuIcon.className = this.$refs.appBarMenuIcon.className.replace('elementHide', 'animate__animated animate__fadeIn');
				}, 500);
				
				clearTimeout(timeoutAppLogo);
				timeoutAppLogo = setTimeout( () => {
					this.$refs.appBarLogo.className = this.$refs.appBarLogo.className.replace('elementHide', 'animate__animated animate__flipInX');
					this.$refs.appBarLogo2.className = this.$refs.appBarLogo2.className.replace('elementHide', 'animate__animated animate__flipInX');
				}, 1000);
				
				clearTimeout(timeoutAppLogin);
				timeoutAppLogin = setTimeout( () => {
					this.$refs.appBarLogin.className = this.$refs.appBarLogin.className.replace('elementHide', 'animate__animated animate__slideInRight');
					this.$refs.appBarLogin2.className = this.$refs.appBarLogin2.className.replace('elementHide', 'animate__animated animate__slideInRight');
				}, 1500);
				
				clearTimeout(timeoutMenu);
				timeoutMenu = setTimeout( () => {
					this.$refs.appBarMenuTab.$el.className = this.$refs.appBarMenuTab.$el.className.replace('animate__animated animate__slideInDown', '');
					this.$refs.appBarMenuIcon.className = this.$refs.appBarMenuIcon.className.replace('animate__animated animate__fadeIn', '');
					
					this.$refs.appBarLogo.className = this.$refs.appBarLogo.className.replace('animate__animated animate__flipInX', '');
					this.$refs.appBarLogo2.className = this.$refs.appBarLogo2.className.replace('animate__animated animate__flipInX', '');
					
					this.$refs.appBarLogin.className = this.$refs.appBarLogin.className.replace('animate__animated animate__slideInRight', '');
					this.$refs.appBarLogin2.className = this.$refs.appBarLogin2.className.replace('animate__animated animate__slideInRight', '');
				}, 2000);
			}
		},
		tabClick (index) {
			
			if(index == null)return;
			
			let top = 0;//Home
			
			switch (index) {
				case 'About Us':
					top = this.$root.$children[0].$children[0].$refs.pageAboutUs.$el.offsetTop;
				break;
				case 'Services':
					top = this.$root.$children[0].$children[0].$refs.pageServices.$el.offsetTop;
				break;
				case 'Training':
					top = this.$root.$children[0].$children[0].$refs.pageTraining.$el.offsetTop;
				break;
				case 'News':
					top = this.$root.$children[0].$children[0].$refs.pageNews.$el.offsetTop;
				break;
				case 'Gallery':
					top = this.$root.$children[0].$children[0].$refs.pageGallery.$el.offsetTop;
				break;
				case 'Contact Us':
					top = this.$root.$children[0].$children[0].$refs.pageContactUs.$el.offsetTop;
				break;
			}
			
			this.$root.$children[0].$children[0].$refs.mainContainer.scrollTo(0, top + 10);
		},
	},
	watch: {
		pageWidth(newValue, oldValue){
			if(oldValue > 960 && newValue < 960 ){
				this.drawer = false;
				this.$refs.menuIconWheel.$el.blur();
			}
		},
	},
	computed:{
		...mapGetters(['logoWidth', 'logoHeight', 'pageWidth'])
	},
	beforeDestroy(){
		clearTimeout(timeoutMenu);
		clearTimeout(timeoutAppBar);
		clearTimeout(timeoutAppLogo);
		clearTimeout(timeoutAppLogin);
		clearTimeout(timeoutRouteName);
	},
}

</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .rotateMenu{
		transform: rotate(90deg);
	}
	
	>>> #overlay {
		position: fixed; /* Sit on top of the page content */
		width: 100%; /* Full width (cover the whole page) */
		height: 100000px; /* Full height (cover the whole page) */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5); /* Black background with opacity */
		z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
		cursor: pointer; /* Add a pointer on hover */
	}
</style>