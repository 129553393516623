export const PAGE_RESIZE			= 'PAGE_RESIZE'
export const PAGE_SEND				= 'PAGE_SEND'
export const PAGE_NOTIFICATION		= 'PAGE_NOTIFICATION'
export const PAGE_SNACKBAR_CLOSE	= 'PAGE_SNACKBAR_CLOSE'
export const PAGE_ERROR_SERVER		= 'PAGE_ERROR_SERVER'
export const PAGE_ERROR_CONNECTION	= 'PAGE_ERROR_CONNECTION'

export const PAGE_FEEDBACK_SUCCESS	= 'PAGE_FEEDBACK_SUCCESS'

export const PAGE_DIALOG_ON			= 'PAGE_DIALOG_ON'
export const PAGE_DIALOG_OFF		= 'PAGE_DIALOG_OFF'