<template>
	<v-container fluid class="pa-0 ma-0 mt-n12">
		<v-img
			:height="pageHeight"
			position="top right"
			:src="require('@/assets/images/alster/pageHome.jpg')"
			alt="Home Image"
			style="justify-content: center; align-items: center;"
			class="d-flex text-center text-uppercase font-weight-bold white--text text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h1 pb-16 pt-16"
		>
			<p ref="caption" v-html="captionArray[captionIndex]" class="elementHide"></p>
			
			<v-btn
				color="primary"
				class="rounded-xl"
				v-intersect="onIntersect"
				v-bind:class="{ animate__animated : pageActive, animate__slideInUp : pageActive, elementHide : !pageActive}"
				:large="this.$vuetify.breakpoint.smAndDown"
				:x-large="this.$vuetify.breakpoint.mdAndUp"
				@mouseover="hover = true"
				@mouseleave="hover = false"
				@click="login"
			>
				<v-icon
					:large="this.$vuetify.breakpoint.mdAndUp"
					:class="{ rotateMenu: hover }"
				>mdi-ship-wheel</v-icon>
				
				JOIN ALSTER
			</v-btn>
		</v-img>	
	</v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import { LOGIN_OPEN } from '@/store/actions/auth';

var animationInterval = '';
var animationTimeout = '';

export default {
	data: () => ({
		page		: 'Home',
		path		: '/',
		pageActive	: false,
		hover		: false,
		captionIndex: 0,
		captionArray: [
			'PROVIDING QUALITY CREWING<br/>AND MARITIME RELATED SERVICES',
			'SUSTAINING COMMITMENT<br/>FOR CUSTOMER SATISFACTION',
			'PROVIDING THE INDUSTRY</br>WITH THE REQUIRED EXPERTISE',
			'SUPPORTING THE SEAFARERS<br/>TO ATTAIN REQUIRED COMPETENCY'
		],
	}),
	methods: {
		login: function(){
			this.$store.dispatch(LOGIN_OPEN);
		},
		onIntersect: function(e){
			if(e[0].isIntersecting){
				
				if( this.$route.path != this.path )this.$router.push(this.path);
				
				animationTimeout = setTimeout( () => {
					this.$refs.caption.className = 'animate__animated animate__fadeIn';
					this.animationOn();
					
					setTimeout( () => {
						this.pageActive = true;
					}, 400);
				}, 500);
			}else{
				
				clearInterval(animationInterval);
				clearTimeout(animationTimeout);
				
				this.pageActive = false;
				this.$refs.caption.className = 'elementHide';
			}
		},
		animationOn: function(){
			clearInterval(animationInterval);
			clearTimeout(animationTimeout);
			
			animationInterval = setInterval( () => {
				if(this.isAuthenticated){
					clearInterval(animationInterval);
					clearTimeout(animationTimeout);
				}else{
					this.$refs.caption.className = 'animate__animated animate__fadeOut';
					
					animationTimeout = setTimeout( () => {
						this.$refs.caption.className = 'animate__animated animate__fadeIn';
						if (++this.captionIndex >= this.captionArray.length) this.captionIndex = 0;
					}, 800);
				}
			}, 6000);
		},
	},
	computed:{
		...mapGetters(['pageHeight', 'isAuthenticated'])
	},
	beforeDestroy(){
		clearTimeout(animationTimeout);
		clearInterval(animationInterval);
	},
};
</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .rotateMenu{
		transform: rotate(90deg);
	}
</style>