import Vue from 'vue'
import Vuex from 'vuex'
import page from './modules/page'
import auth from './modules/auth'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		page,
		auth,
		user
	}
})
