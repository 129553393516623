<template>
	<v-container fluid class="px-0 mx-0">
		<v-card
			elevation="0"
			v-intersect="pageViewIn"
			:min-height="this.$store.getters.pageHeight"
			class="ma-0 pa-0"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
				class="mt-16"
			>
				<v-col
					cols="12"
					class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text-center text-uppercase font-weight-regular"
					v-bind:class="{ animate__animated : pageActive, animate__slideInDown : pageActive, elementHide : !pageActive }"
				>
					{{ page }}
				</v-col>
				
				<v-col
					cols="11" sm="11" md="10" lg="9" xl="8"
					class="mb-5 text-center font-weight-light text-uppercase text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6"
					v-bind:class="{ animate__animated : pageActive, animate__slideInUp : pageActive, elementHide : !pageActive }"
					style="overflow : hidden;"
				>
					{{ pageSubtitle }}
				</v-col>
			</v-row>
				
			<v-row align="center" justify="center" class="mb-12" no-gutters 
				v-intersect="onIntersect"
				v-bind:class="{ animate__animated : pageActive, animate__fadeIn : pageActive, elementHide : !pageActive }"
			>
				
				<v-col cols="12" sm="10" md="10" lg="5" xl="5" class="mr-lg-2 mr-xl-2">
					<v-row no-gutters align="center" justify="center">
						<v-col cols="12">
							<span
								v-bind:class="{ animate__animated : pageActive, animate__fadeIn : pageActive, elementHide : !pageActive }"
								class="primary pa-3 white--text text-overline font-weight-bold rounded-t"
							>
								<v-icon small dark class="pr-1 mb-1">mdi-star</v-icon>TOP STORY
							</span>
						</v-col>
						
						<v-col cols="12">
							<v-hover v-slot:default="{ hover }">
								<v-carousel :cycle="mainNewsCycle" :width="newsMainImageW" :height="newsMainImageH" :show-arrows="false" hide-delimiters>
									<v-carousel-item
										v-for="mainImages in mainNewsItems"
										:key="mainImages"
										:src="require(`@/assets/images/news/${mainImages}`)"
										reverse-transition="transition-fast-in-fast-out"
										transition="transition-fast-in-fast-out"
									>
										<v-icon color="blue darken-2" :small="$vuetify.breakpoint.xsOnly" :medium="$vuetify.breakpoint.smAndUp" :large="$vuetify.breakpoint.mdAndUp"
											style="transform: rotate(30deg); float:right;" class="mt-2 mr-2"
										>mdi-pin</v-icon>
										<div
											class="d-flex transition-fast-in-fast-out white--text newsMainReveal"
											v-bind:class="{ newsMainRevealOn : hover }"
										>
											<p class="text-center text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 px-1" style="line-height:120%; margin:auto;">
												MESSAGE FROM THE CEO<br/>
												<font v-show="hover" v-html="text"
													class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
													style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
												></font>
												<v-btn v-show="hover" @click="newsReadMore('main')" color="primary" tile
													class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
													READ MORE
												</v-btn>
											</p>
										</div>
									</v-carousel-item>
								</v-carousel>
							</v-hover>
						</v-col>
					</v-row>
				</v-col>
				
				<v-col cols="12" sm="10" md="10" lg="5" xl="5" class="mt-2 mt-lg-0 mt-xl-0">
					<v-row no-gutters align="center" justify="center">
						<v-col cols="6">
							<span
								v-bind:class="{ animate__animated : pageActive, animate__fadeIn : pageActive, elementHide : !pageActive }"
								class="primary pa-3 white--text text-overline font-weight-bold rounded-t"
							>
								<v-icon small dark class="pr-1 mb-1">mdi-star</v-icon>RECENT STORY
							</span>
						</v-col>
						
						<v-col cols="6"
							class="text-right"
							v-bind:class="{ animate__animated : pageActive, animate__fadeIn : pageActive, elementHide : !pageActive }"
						>	
							{{ 0 >= (currentIndex-2)? currentIndex+6 : currentIndex-2 }} - 
							{{ currentIndex+1 }} of 
							{{ newsListItems.length }}
							
							<v-btn
								color="primary ml-2"
								style="border-radius: 5px 0px 0px 0px;"
								class="pa-2"
								@click="newsBack"
							>
								<v-icon color="white">mdi-arrow-left</v-icon>
							</v-btn>

							<v-btn
								color="primary"
								style="border-radius: 0px 5px 0px 0px;"
								@click="newsNext"
							>
								<v-icon color="white">mdi-arrow-right</v-icon>
							</v-btn>
						</v-col>
							
						<v-col cols="6" class="pb-1 pb-lg-2 pb-xl-2 pr-1 pr-lg-2 pr-xl-2">
							<v-hover v-slot:default="{ hover }">
								<v-window v-model="window1">
									<v-window-item :value="0">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex1].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex1].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex1].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex1)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
									
									<v-window-item :value="1">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex5].image}`)" alt="Seafarer Image">
										
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex5].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex5].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex5)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
								</v-window>
							</v-hover>
						</v-col>
						
						<v-col cols="6" class="pb-1 pb-lg-2 pb-xl-2">
							<v-hover v-slot:default="{ hover }">
								<v-window v-model="window2">
									<v-window-item :value="0">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex2].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex2].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex2].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex2)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
									
									<v-window-item :value="1">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex6].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex6].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex2].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex6)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
								</v-window>
							</v-hover>
						</v-col>
						
						<v-col cols="6" class="pr-1 pr-lg-2 pr-xl-2">
							<v-hover v-slot:default="{ hover }">
								<v-window v-model="window3">
									<v-window-item :value="0">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex3].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex3].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex3].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex3)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
									
									<v-window-item :value="1">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex7].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex7].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex7].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex7)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
								</v-window>
							</v-hover>
						</v-col>
						
						<v-col cols="6">
							<v-hover v-slot:default="{ hover }">
								<v-window v-model="window4">
									<v-window-item :value="0">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex4].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex4].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex4].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex4)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
									
									<v-window-item :value="1">
										<v-img class="d-flex" :width="newsListImageW" :height="newsListImageH" :src="require(`@/assets/images/news/${newsListItems[newsListIndex8].image}`)" alt="Seafarer Image">
											
											<div
												class="d-flex transition-fast-in-fast-out white--text newsListReveal"
												v-bind:class="{ newsListRevealon : hover }"
											>
												<p class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 px-1" style="line-height:120%; margin:auto;">
													{{ newsListItems[newsListIndex8].title }}<br/>
													<font v-show="hover" v-html="newsListItems[newsListIndex8].content"
														class="text-caption text-sm-body-2 text-md-body-1 text-lg-subtitle-1 text-xl-subtitle-1"
														style="line-height:110%; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;"
													></font>
													<v-btn v-show="hover" @click="newsReadMore(newsListIndex8)" color="primary" tile
														class="mt-2 mt-sm-2 mt-md-1 mt-lg-3 mt-xl-4" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" :medium="$vuetify.breakpoint.mdAndUp">
														READ MORE
													</v-btn>
												</p>
											</div>
											
										</v-img>
									</v-window-item>
								</v-window>
							</v-hover>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			
			<v-dialog
				v-model="dialog"
				width="700"
				scrollable
				@click:outside="newsListLoop"
				:fullscreen="$vuetify.breakpoint.xsOnly"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						{{ dialogTitle }}
						<v-spacer></v-spacer>
						
						<v-btn icon
							@click="dialog = false; newsListLoop();"
						>
							<v-icon small class="white--text">mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-card-text class="inline-flex pt-2">
						<v-img class="mt-1 mr-2 mb-1" style="float: left;" width="250" :src="require(`@/assets/images/news/${dialogImage}`)" alt="Seafarer Image"></v-img>
						<font v-html="dialogText"></font>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="dialog = false; newsListLoop();"
						>
							Close
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card>
	</v-container>
</template>

<script>

import { mapGetters } from 'vuex';

var newsListInterval = '';

export default {
	data: () => ({
		page			: 'News',
		path			: '/news',
		pageSubtitle	: "TODAY'S NEWS IS GOOD NEW'S",
		pageActive		: false,
		dialog			: false,
		dialogTitle		: '',
		dialogImage		: 'news1.jpg',
		dialogText		: '',
		mainNewsItems	: [
			'ceoMessage-1.jpg',
			'ceoMessage-2.jpg'
		],
		mainNewsCycle	: false,
		currentIndex	: 3,
		window			: 4,
		window1			: 0,
		window2			: 0,
		window3			: 0,
		window4			: 0,
		newsListIndex1	: 0,
		newsListIndex2	: 1,
		newsListIndex3	: 2,
		newsListIndex4	: 3,
		newsListIndex5	: 4,
		newsListIndex6	: 5,
		newsListIndex7	: 6,
		newsListIndex8	: 7,
		newsListItems	: [
			{
				title:	'NEW NORMAL AT ALSTER',
				image:	'news1.jpg',
				content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
			},
			{
				title:	'ALSTER GETS EVEN BETTER AT 20',
				image:	'news2.jpg',
				content:'Amidst the significant developments happening in the manning and crewing business, Alster International Shipping Services, Inc. remains at the forefront by innovating relevant policies and programs that aim not only to be at par but ahead of the competition.<br/><br/>Celebrating its 20th year of crewing excellence, Alster continues to be one of the highly–regarded manning and crewing agencies in the country. Through the years, the company is steadfast in its commitment to provide good employment opportunities and benefits to Filipino seafarers.<br/><br/>Having just marked its 20th year in the business on August 28, 2008, Alster can truly take pride of the fact that excellence and quality have carried it all the way to the top of the coporate ladder of success. After two decades of thriving in a competitive business environment, Alster is now regarded as being in the class of some of the country’s most reputable manning and crewing agencies, with more elbow room for continued growth in the future.<br/><br/>Physically mirroring the metamorphosis of Alster into a bankable bastion of quality crewing is the company’s newly renovated and sophisticatedly-looking offices at the 2053 Building along Edison Street in Makati City. From merely occupying average and typically-looking manning offices not too long ago, the interior offices of Alster Shipping have been transformed into a stylish and ultra-modern workplace complete with appropriate furniture and equipment, which can surely give the classy offices of international advertising firms a run for their money.',
			},
			{
				title:	'ALSTER SEAJOBS NOW ONLINE',
				image:	'news3.jpg',
				content:'We are proud to announce that Alster Sea Jobs Site is now available online!<br/><br/>Alster SeaJobs is a Seaman Job site dedicated in helping Filipino Seafarers seeking Jobs at Sea. Qualified Seaman may apply online or search for our seajobs database for various job offerings posted in our website.',
			},
			{
				title:	"PRESIDENT'S MESSAGE",
				image:	'news4.jpg',
				content:'My Dear Colleagues,<br/><br/>The existence of Alster International Shipping Services, Inc. in 1988 until now is the result of hardwork and dedication that brought this company to where it is today.<br/><br/>Being part of the team, I encourage you to maintain your committment in delivering best quality services to our clients. These qualities (teamwork and dedication) made us seasoned to overcome current and future challenges.<br/><br/>Let us always do our best in the performance of our duties in compliance to our company policies for us to always exceed the expectations of our customers.<br/><br/>We have reaped the fruits of our labour. And that is the sustained trust and confidence that our principals have given to us. The company serves by the union of your individual discipline setting as an example in all our undertakings and creating a workforce backed up by values of motivation, dedication, and the ability to face the challenges even beyond the margins of your comfort zone.<br/><br/>It is remarkable that our success if fully manifested by our attained culture. The culture of responsibility and accountability by the careful management of risks that we face along the way.<br/><br/>Keep being dynamic for us to succeed with this challenges and generate more opportunities to Filipinos.<br/><br/>Always have a blessed day!',
			},
			{
				title:	'JAPAN TOUR',
				image:	'news5.jpg',
				content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
			},
			{
				title:	'VIETNAM TOUR',
				image:	'news6.jpg',
				content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
			},
			{
				title:	'THAILAND TOUR',
				image:	'news7.jpg',
				content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
			},
			{
				title:	'SARIAYA SECRET',
				image:	'news8.jpg',
				content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
			},
		],
		text: 'We are proud that Alster is a hands-on Manning and Crewing Agency. We go the extra mile to ensure we provide for the needs of our Filipino seafarers and their families even as we continue to evolve and strive to meet the expectations, not to mention the high standards of our principals.<br/><br/>More than ourselves and the office personnel of Alster, the substantial amount we poured in transforming our workplace to become what it is today is actually meant to serve our Filipino seafarers better. We only want to give the best for them and their families so we made the extra effort to ensure that they will get to feel at home in Alster.<br/><br/>In addition to providing excellent and quality maritime services to Filipino seafarers, we offer one of the most competitive remuneration packages in the maritime industry. We also provide our seafarers and their families with comprehensive health care benefits. We also have a program that extends financial assistance to our seafarers who may need of such.',
	}),
	computed:{
		...mapGetters(['galleryImageW', 'galleryImageH', 'newsMainImageW', 'newsListImageW', 'newsMainImageH', 'newsListImageH'])
	},
	methods: {
		pageViewIn: function(e){
			if(e[0].isIntersecting){
				if( this.$route.path != this.path )this.$router.push(this.path);
				this.pageActive = true;
			}else{
				this.pageActive = false;
			}
		},
		newsReadMore (article) {
			this.mainNewsCycle = false;
			clearInterval(newsListInterval);
				
			if( article == 'main' ){
				this.dialogTitle = 'MESSAGE FROM THE CEO';
				this.dialogImage = 'ceoMessage-1.jpg'
				this.dialogText = this.text;
				this.dialog = true;
			}else{
				this.dialogTitle = this.newsListItems[article].title;
				this.dialogImage = this.newsListItems[article].image;
				this.dialogText = this.newsListItems[article].content;
				this.dialog = true;
			}
		},
		onIntersect (entries) {  
			if(entries[0].isIntersecting){
				this.newsListLoop();
			}else{
				this.mainNewsCycle = false;
				clearInterval(newsListInterval);
			}
		},
		newsBack(){
			this.mainNewsCycle = false;
			clearInterval(newsListInterval);
			
			if (--this.window < 1) this.window = 8;
				
			if (--this.currentIndex < 0) this.currentIndex = (this.newsListItems.length - 1);
			
			this.newsUpdate();
			this.newsListLoop();
		},
		newsNext(){
			this.mainNewsCycle = false;
			clearInterval(newsListInterval);
			
			if (++this.window > 8) this.window = 1;
				
			if (++this.currentIndex >= this.newsListItems.length) this.currentIndex = 0;
			
			this.newsUpdate();
			this.newsListLoop();
		},
		newsListLoop (){
			this.mainNewsCycle = true;
			
			newsListInterval = setInterval( () => {
			
				if (++this.window > 8) this.window = 1;
				
				if (++this.currentIndex >= this.newsListItems.length) this.currentIndex = 0;
				
				this.newsUpdate();
				
			}, 4000);
		},
		newsUpdate(){
			switch (this.window) {
				case 1:
					this.newsListIndex1 = this.currentIndex;
					this.window1 = (this.window1 == 0)? 1 : 0;
				break;
				case 2:
					this.newsListIndex2 = this.currentIndex;
					this.window2 = (this.window2 == 0)? 1 : 0;
				break;
				case 3:
					this.newsListIndex3 = this.currentIndex;
					this.window3 = (this.window3 == 0)? 1 : 0;
				break;
				case 4:
					this.newsListIndex4 = this.currentIndex;
					this.window4 = (this.window4 == 0)? 1 : 0;
				break;
				case 5:
					this.newsListIndex5 = this.currentIndex;
					this.window1 = (this.window1 == 0)? 1 : 0;
				break;
				case 6:
					this.newsListIndex6 = this.currentIndex;
					this.window2 = (this.window2 == 0)? 1 : 0;
				break;
				case 7:
					this.newsListIndex7 = this.currentIndex;
					this.window3 = (this.window3 == 0)? 1 : 0;
				break;
				case 8:
					this.newsListIndex8 = this.currentIndex;
					this.window4 = (this.window4 == 0)? 1 : 0;
				break;
			}
		},
	},
	beforeDestroy(){
		clearInterval(newsListInterval);
	},
}
</script>

<style scoped>
    >>> .opacity {
		opacity: 0.1;
    }
	
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .newsMainReveal {
		align-items: center;
		bottom: 0;
		justify-content: center;
		position: absolute;
		outline: 1px black;
		width: 100%;
		height:25%;
		background-color: rgba(67, 67, 67, .5);
	}
	
	>>> .newsMainRevealOn{
		height:50%;
	}

	>>> .newsListReveal {
		align-items: center;
		bottom: 0;
		justify-content: center;
		position: absolute;
		outline: 1px black;
		width: 100%;
		height:50%;
		background-color: rgba(67, 67, 67, .5);
	}
	
	>>> .newsListRevealon{
		height:100%;
	}
</style>