import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'

//const UserDashboard		= () => import('../views/userPage/dashboard.vue')
const UserSummary		= () => import('../views/userPage/summary.vue')
const UserBasicinfo		= () => import('../views/userPage/basicinfo.vue')
const UserContactinfo	= () => import('../views/userPage/contactinfo.vue')
const UserEducation		= () => import('../views/userPage/education.vue')
const UserEmployment	= () => import('../views/userPage/employment.vue')
const UserTraining		= () => import('../views/userPage/training.vue')
const UserLocaldoc		= () => import('../views/userPage/localdoc.vue')
const UserForeigndoc	= () => import('../views/userPage/foreigndoc.vue')
const UserCertificate	= () => import('../views/userPage/certificate.vue')
const UserVisa			= () => import('../views/userPage/visa.vue')
const UserFamily		= () => import('../views/userPage/family.vue')
const UserOtherinfo		= () => import('../views/userPage/otherinfo.vue')
const UserAccount		= () => import('../views/userPage/account.vue')
const UserHelpCenter	= () => import('../views/userPage/helpcenter.vue')
const UserLogout		= () => import('../views/userPage/logout.vue')

//const AdminDashboard	= () => import('../views/adminPage/dashboard.vue')
const adminApplicant	= () => import('../views/adminPage/applicants.vue')

const Dashboard	= () => import('../views/dashboard.vue')

Vue.use(VueRouter)

const routes = [
	{ path: '/',				name: '',					meta: { admin: false, auth: false,	title: '' },						component: Dashboard		},
	{ path: '/about',			name: 'About Us',			meta: { admin: false, auth: false,	title: ' | About Us' }		},
	{ path: '/services',		name: 'Services',			meta: { admin: false, auth: false,	title: ' | Services' }		},
	{ path: '/training',		name: 'Training',			meta: { admin: false, auth: false,	title: ' | Training' }		},
	{ path: '/news',			name: 'News',				meta: { admin: false, auth: false,	title: ' | News' }			},
	{ path: '/gallery',			name: 'Gallery',			meta: { admin: false, auth: false,	title: ' | Gallery' }		},
	{ path: '/contact',			name: 'Contact Us',			meta: { admin: false, auth: false,	title: ' | Contact Us' }	},
	
	{ path: '/summary', 		name: 'PROFILE SUMMARY',	meta: { admin: false, auth: true,	title: ' | Profile Summary' },		component: UserSummary		},
	{ path: '/basicinfo', 		name: 'BASIC INFORMATION',	meta: { admin: false, auth: true,	title: ' | Basic Information' },	component: UserBasicinfo	},
	{ path: '/contactinfo', 	name: 'CONTACT INFORMATION',meta: { admin: false, auth: true,	title: ' | Contact Information' },	component: UserContactinfo  },
	{ path: '/education', 		name: 'EDUCATION',			meta: { admin: false, auth: true,	title: ' | Education' },			component: UserEducation 	},
	{ path: '/employment', 		name: 'EMPLOYMENT',			meta: { admin: false, auth: true,	title: ' | Employment' },			component: UserEmployment	},
	{ path: '/trainings', 		name: 'TRAINING',			meta: { admin: false, auth: true,	title: ' | Training' },				component: UserTraining 	},
	{ path: '/localdocument', 	name: 'LOCAL DOCUMENT',		meta: { admin: false, auth: true,	title: ' | Local Document' },		component: UserLocaldoc 	},
	{ path: '/foreigndocument', name: 'FOREIGN DOCUMENT',	meta: { admin: false, auth: true,	title: ' | Foreign Document' },		component: UserForeigndoc  	},
	{ path: '/certificate', 	name: 'CERTIFICATE',		meta: { admin: false, auth: true,	title: ' | Certificates' },			component: UserCertificate	},
	{ path: '/visa', 			name: 'VISA',				meta: { admin: false, auth: true,	title: ' | Visa' },					component: UserVisa			},
	{ path: '/family', 			name: 'FAMILY',				meta: { admin: false, auth: true,	title: ' | Family' },				component: UserFamily		},
	{ path: '/otherinfo', 		name: 'QUESTIONNAIRE',		meta: { admin: false, auth: true,	title: ' | Questionnaire' },		component: UserOtherinfo 	},
	{ path: '/account', 		name: 'ACCOUNT SETTINGS',	meta: { admin: false, auth: true,	title: ' | User Account' },			component: UserAccount 		},
	{ path: '/helpcenter', 		name: 'HELP CENTER',		meta: { admin: false, auth: true,	title: ' | Help Center' },			component: UserHelpCenter 		},
	{ path: '/logout', 			name: 'LOGOUT',				meta: { admin: false, auth: false,	title: '' },						component: UserLogout 		},
	
	{ path: '/applicants',		name: 'Applicants',				meta: { admin: true,  auth: true,	title: '' },						component: adminApplicant 		},
	
/*  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ /*'../views/About.vue')
  }*/
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const DEFAULT_TITLE = 'Alsterph';

router.beforeEach((to, from, next) => {
	if( typeof to.meta.auth !== 'undefined' ){
		if(to.meta.admin && to.meta.auth){
			if( store.getters.isAdmin && store.getters.isAuthenticated ){
				next();
			}else{
				next({
					path: from.path
				});
			}
		}else if(to.meta.auth){
			if( !store.getters.isAdmin && store.getters.isAuthenticated ){
				next();
			}else{
				next({
					path: from.path
				});
			}
		}else{
			next();
		}
	}else{
		next({
			path: from.path
		});
	}
});

router.afterEach((to) => {
    document.title = DEFAULT_TITLE+to.meta.title || DEFAULT_TITLE;
});

export default router