<template>
	<v-app>
		<!-- Sizes your main based upon application components -->
		<v-main v-resize="onResize">
			<!-- Provides the application the proper gutter -->
			
			<!-- mainContainer use for scrolling in Header.vue -->
			<v-container
				fluid
				class="py-0 px-0 page-section-container"
				ref="mainContainer"
				v-scroll.self="onScroll"
			>
				
				<v-row
					no-gutters
					@mouseover="hover = true; animateFeedbackStop();"
					@mouseleave="hover = false; animateFeedback();"
					@click="customerFeedback"
					class="primary mb-2 white--text"
					style="cursor: pointer; position: absolute; right: 0; bottom: 0; z-index: 100; margin-right:17px; height:66px; width:66px;"
				>
					<v-col v-show="hover" cols="auto" class="font-weight-light mx-auto my-auto text-center" style="line-height: 80%;">
						<font style="font-size: 8pt; letter-spacing: 0.05em">RATE<br/>THIS PAGE</font>
					</v-col>
					
					<v-col v-show="!hover" cols="auto" class="font-weight-light mx-auto my-auto text-center">
						<font style="font-size: 12pt;" class="flip-link" v-bind:class="{ 'flip-link-return' : feedbackFlip, 'flip-link-turn' : !feedbackFlip }">
							<span>{{ feedbackText }}</span><br/>
						</font>
						<font style="font-size: 8pt; letter-spacing: 0.05em">FEEDBACK</font>
					</v-col>
				</v-row>
				
				<Header/>
				
				<Home ref="pageHome" class="page-section"/>
				
				<AboutUs ref="pageAboutUs" class="page-section"/>
				
				<Services ref="pageServices" class="page-section"/>
				
				<Training ref="pageTraining" class="page-section"/>
				
				<News ref="pageNews" class="page-section"/>
				
				<Gallery ref="pageGallery" class="page-section"/>
				
				<ContactUs ref="pageContactUs" class="page-section"/>
				
				<v-snackbar
					v-model="snackBar"
					:color="snackBarColor"
					bottom
					timeout="9000"
					class="text-h3 font-weight-bold"
				>
					<v-icon dark small class="pr-2">{{ snackBarIcon }}</v-icon>{{ snackBarMsg }}
					<template v-slot:action="{ attrs }">
						<v-icon small v-bind="attrs" @click="snackBarClose">mdi-close-circle</v-icon>
					</template>
				</v-snackbar>
				
				<v-dialog
					v-model="loginActive"
					scrollable
					fullscreen
					persistent
					@keydown.esc="loginClose"
				>
					<v-card
						style="background: linear-gradient(30deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)"
					>
						<v-card-text style="max-height: 500px; width:400px; min-width:300px; max-width:400px; background-color:white;" class="my-auto mx-auto">
						
							<v-row no-gutters align="center" justify="center" style="max-height: 60px; background-color:white;" class="rounded-t">
									<v-col cols="1">
										&nbsp;
									</v-col>
									
									<v-col cols="10" class="pt-3">
										<v-tabs
											v-model="tab"
											centered
										>
											<v-tab>LOGIN</v-tab>
											<v-tab>REGISTER</v-tab>
										</v-tabs>
									</v-col>
									
									<v-col cols="1" class="pb-3">
										<v-icon small @click="loginClose" style="float:right;">mdi-close</v-icon>
									</v-col>
								
									<v-col cols="12">
										<v-card style="max-height: 400px;" elevation="0">
											<v-tabs-items v-model="tab">
												<v-tab-item>
													<Login/>
												</v-tab-item>
												
												<v-tab-item>
													<Register/>
												</v-tab-item>
											</v-tabs-items>
										</v-card>
									</v-col>
								</v-row>
						</v-card-text>
					</v-card>
				</v-dialog>
				
				<v-dialog
					v-model="dialogCustomerFeedback"
					width="600"
					scrollable
					@keydown.esc="dialogCustomerFeedbackClose"
				>
					
					<v-card>
						<v-card-text>
							<v-row no-gutters>
								<v-col cols="12" class="text-center text-body-1 mt-10">
									<v-icon
										small
										@click="dialogCustomerFeedbackClose"
										style="float:right;"
										class="mt-n5"
									>mdi-close</v-icon>
									
									When you think about your visit to alsterph.com:
									<br/>
									How would you rate your experience?
								</v-col>
								
								<v-col cols="12" class="text-center mt-5">
									<font class="text-h5">({{ rating }}/5)</font>
									<v-rating
										v-model="rating"
										color="yellow darken-3"
										background-color="grey darken-1"
										empty-icon="$ratingFull"
										hover
										size="30"
									></v-rating>
								</v-col>
								
								<v-col cols="12" class="text-center text-body-1 mt-10">
									Please tell us about your experience. Be as detailed as possible about anything we're doing well and/or could do better—what we learn from you helps make us better!
								</v-col>
								
								<v-col cols="12" class="text-center mt-2">
									<v-textarea
										v-model="feedBackDetails"
										auto-grow
										clearable
										rows="1"
										row-height="10"
										outlined
									></v-textarea>
								</v-col>
								
								<v-col cols="12" class="text-center mt-2 mb-5">
									<v-btn
										color="primary"
										:loading="buttonLoading"
										@click="submitFeedback"
									>
										Submit
									</v-btn>
								</v-col>
								
							</v-row>
						</v-card-text>
					</v-card>
					
				</v-dialog>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>	

import { mapGetters } from 'vuex';
import { LOGIN_CLOSE } from '@/store/actions/auth';
import { PAGE_RESIZE, PAGE_SNACKBAR_CLOSE, PAGE_FEEDBACK_SUCCESS } from '@/store/actions/page';

/*LAYOUT*/
import Header from 		'./Header.vue'

/*PAGES*/
import Home from 		'./../publicPage/Home.vue'
import AboutUs from 	'./../publicPage/About.vue'
import Services from 	'./../publicPage/Services.vue'
import Training from 	'./../publicPage/Training.vue'
import News from 		'./../publicPage/News.vue'
import Gallery from 	'./../publicPage/Gallery.vue'
import ContactUs from 	'./../publicPage/Contact.vue'

const Login				= () => import('@/components/Public/login');
const Register			= () => import('@/components/Public/register');

var timeoutScroll = null;
var intervalFeedback = null;

export default {
	components: {
		Header,
		
		Home,
		AboutUs,
		Services,
		Training,
		News,
		Gallery,
		ContactUs,
		
		Login,
		Register,
	},
	name: 'MAINPAGE',
	data() {
		return {
			tab				: 0,
			hover			: false,
			feedBackDetails	: '',
			feedbackText	: '[ + ]',
			buttonLoading	: false,
			rating			: 0,
			feedbackFlip	: false,
			dialogCustomerFeedback: false,
		}
	},
	mounted () {
		this.onResize();
		this.animateFeedback();
    },
    methods: {
		animateFeedback(){
			intervalFeedback = setInterval( () => {
				this.feedbackFlip = !this.feedbackFlip;
				setTimeout( () => {
					this.feedbackText = (this.feedbackText == '[ - ]')? '[ + ]' : '[ - ]';
				}, 407);
			}, 5000);
		},
		animateFeedbackStop(){
			clearInterval(intervalFeedback);
		},
		customerFeedback(){
			this.rating = 0;
			this.feedBackDetails = '';
			this.dialogCustomerFeedback = true;
		},
		dialogCustomerFeedbackClose(){
			this.dialogCustomerFeedback = false;
		},
		submitFeedback(){
			this.buttonLoading = true;
			setTimeout( () => {
				this.$store.dispatch(PAGE_FEEDBACK_SUCCESS);
				this.dialogCustomerFeedbackClose();
				this.buttonLoading = false;
			}, 1000);
		},
		onScroll (e){
			clearTimeout(timeoutScroll);
			timeoutScroll = setTimeout( () => {
				
				let pos = e.target.scrollTop;
				
				let aboutUs = this.$refs.pageAboutUs.$el.offsetTop;
				let service = this.$refs.pageServices.$el.offsetTop;
				let training= this.$refs.pageTraining.$el.offsetTop;
				let news	= this.$refs.pageNews.$el.offsetTop;
				let gallery	= this.$refs.pageGallery.$el.offsetTop;
				let contact	= this.$refs.pageContactUs.$el.offsetTop;
				
				if( pos >= contact ){
					if( this.$route.path != '/contact' )this.$router.push('/contact');
				}else if( pos >= gallery ){
					if( this.$route.path != '/gallery' )this.$router.push('/gallery');
				}else if( pos >= news ){
					if( this.$route.path != '/news' )this.$router.push('/news');
				}else if( pos >= training ){
					if( this.$route.path != '/training' )this.$router.push('/training');
				}else if( pos >= service ){
					if( this.$route.path != '/services' )this.$router.push('/services');
				}else if( pos >= aboutUs ){
					if( this.$route.path != '/about' )this.$router.push('/about');
				}else{
					if( this.$route.path != '/' )this.$router.push('/');
				}
				
			}, 500);
		},
		loginClose (){
			this.$store.dispatch(LOGIN_CLOSE);
		},
		onResize () {
			this.$store.dispatch(PAGE_RESIZE);
		},
		snackBarClose (){
			this.$store.dispatch(PAGE_SNACKBAR_CLOSE);
		},
    },
	computed:{
		...mapGetters(['pageHeight', 'snackBar', 'snackBarColor', 'snackBarIcon', 'snackBarMsg', 'loginActive'])
	},
	beforeDestroy(){
		clearTimeout(timeoutScroll);
		clearInterval(intervalFeedback);
	},
};
</script>

<style scoped>
	>>> .page-section-container {
		max-height: 100vh;
		overflow-y: scroll;
		scroll-behavior: smooth;
		scroll-snap-type: y mandatory;
	}
	
	>>> .page-section {
		min-height: 100vh;
		scroll-snap-align: start;
	}
	
	>>> .flip-link {
		display: inline-block;
		overflow: hidden;

		vertical-align: top;

		perspective: 600px;
		perspective-origin: 50% 50%;
	}

	>>> .flip-link span {
		position: relative;

		transition: all 1000ms ease;
		transform-origin: 50% 0%;

		transform-style: preserve-3d;
	}
	
	>>> .flip-link-return {
		transform: rotateY( 180deg );
		transition: all 1000ms ease;
	}
	
	>>> .flip-link-turn {
		transform: rotateY( 360deg );
		transition: all 1000ms ease;
	}
</style>