<template>
	<v-container fluid class="px-0 mx-0">
		<v-card
			elevation="0"
			v-intersect="pageViewIn"
			:min-height="this.$store.getters.pageHeight"
			class="ma-0 pa-0"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
				class="mt-16"
			>
				<v-col
					cols="12"
					v-intersect="onIntersect"
					name="animate__animated animate__slideInDown"
					class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text-center text-uppercase font-weight-regular elementHide"
				>
					{{ page }}
				</v-col>
				
				<v-col
					cols="11" sm="11" md="10" lg="9" xl="8"
					v-intersect="onIntersect"
					name="animate__animated animate__slideInUp"
					class="mb-16 text-center font-weight-light text-uppercase text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 overflowHide elementHide"
				>
					{{ pageSubtitle }}
				</v-col>
			</v-row>
			
			<v-row no-gutters justify="center">
				<v-col cols="12" sm="10" md="5" lg="5" xl="5" v-for="service in servicesItems" :key="service.icons" class="my-0 my-md-5 my-lg-5 my-xl-5">
					<v-hover
						v-slot:default="{ hover }"
					>
						<v-row
							no-gutters
						>
							<v-col
								cols="2" sm="1" md="2" lg="1" xl="1"
								v-intersect="onIntersect"
								name="animate__animated animate__fadeIn"
								class="text-center elementHide"
							>
								<v-icon :large="iconLG" :x-large="iconXL" :color="hover? 'blue darken-1' : 'blue darken-3'">{{ service.icons }}</v-icon>
							</v-col>
							
							<v-col cols="10" sm="11" md="10" lg="11" xl="11" class="pl-0 pl-sm-5 pl-md-0 pl-lg-1 pl-xl-0" style="overflow : hidden;">
							
								<p
									v-intersect="onIntersect"
									name="animate__animated animate__slideInDown"
									class="serviceTitle text-subtitle-1 text-md-h6 text-lg-h6 text-xl-h6 elementHide"
								>{{ service.title }}</p>
								
								<p
									v-intersect="onIntersect"
									name="animate__animated animate__slideInLeft"
									class="font-weight-light pr-2 text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-subtitle-1 text-xl-subtitle-1 elementHide"
								>{{ service.description }}</p>
								
							</v-col>
						</v-row>
					</v-hover>
				</v-col>
			</v-row>
			
			<v-row no-gutters justify="center" class="grey darken-4 white--text py-16 my-10">
				<v-col
					v-for="list in servicesList"
					:key="list.title" cols="10"
					sm="4" md="4" lg="3" xl="3"
					class="text-center"
					style="overflow : hidden;"
				>
					<v-icon dark x-large
						v-intersect="onIntersect"
						name="animate__animated animate__fadeIn"
						class="elementHide"
					>{{ list.icons }}</v-icon>
					<p
						v-intersect="onIntersect"
						name="animate__animated animate__slideInDown"
						class="serviceTitle2 text-subtitle-1 text-md-h6 text-lg-h6 text-xl-h6 elementHide"
					>{{ list.title }}</p>
					<p
						v-intersect="onIntersect"
						name="animate__animated animate__slideInUp"
						class="font-weight-light mx-2 text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-subtitle-1 text-xl-subtitle-1 elementHide"
					>{{ list.description }}</p>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>

export default {
	data: () => ({
		page		: 'Services',
		path		: '/services',
		pageSubtitle: 'Our Core of Good Service – Teamwork',
		servicesList: [
			{
				title		: 'On-line Recruitment',
				icons		: 'mdi-account-multiple-plus',
				description	: 'Our on-line recruitment system was developed as innovative hiring solutions customized to meet our hiring needs. Job seekers can use our on-line job search and on-line job application. ',
			},
			{
				title		: 'Documentation',
				icons		: 'mdi-file-document',
				description	: 'Our fully computerized system avoid the risk of inappropriate or inadequate crew documents delaying your vessels in ports. Alster ensures all required documents, trainings, and certificates are properly assessed and complied by all crew-members. ',
			},
			{
				title		: 'Crew Planning',
				icons		: 'mdi-calendar-account',
				description	: 'Our system matches seafarers who are available to vessels they previously worked on, and you benefit from re-employing crews experienced in managing your vessels, know your procedures, and your policies. ',
			},
		],
		servicesItems: [
			{
				title		: 'Crew Management',
				icons		: 'mdi-file-document-edit',
				description	: 'Our strength is the sincerity of our commitment to provide valuable services to our clients. Our crew management system ensures that seafarers provided to our principals are fit for the requirements and contingent to face the challenges of their working reality.',
			},
			{
				title		: 'Allotment',
				icons		: 'mdi-home-currency-usd',
				description	: 'The accounting department handles the generation of monthly allotment and special allotments. They are also responsible for handling of principals’ accounts, tax tonnage reporting, social benefits, master’s cash account control, and monthly disbursement accounts.',
			},
			{
				title		: 'Family Center',
				icons		: 'mdi-account-group',
				description	: 'We constantly strive to perform better each day not only by employing competencies to our seafarers but also protect their interests and their families.',
			},
			{
				title		: 'Pre-Board Accommodation',
				icons		: 'mdi-bed',
				description	: 'With out state of the art facilities and equipment, we are sure to accommodate every seafarers needs.',
			},
			{
				title		: 'Training',
				icons		: 'mdi-clipboard-list',
				description	: 'To achieve work quality and peak performance, you need new technologies driven by highly trained seafarers. At Alster, we believe that how you deploy, manage, and develop your resources determines whether you get there first or play “catch-up.” ',
			},
			{
				title		: 'Travel Arrangement',
				icons		: 'mdi-airplane-takeoff',
				description	: 'Through our travel agents as our business partners, our communication personnel handle the seafarers’ travel arrangements. Its main functions are travel arrangements for joining and disembarking seafarers, coordinating with agents abroad, Monitoring immigration, custom and health authorities requirements, follow up and assistance to en route seafarers.',
			},
		]
	}),
	methods:{
		pageViewIn: function(e){
			if(e[0].isIntersecting)if( this.$route.path != this.path )this.$router.push(this.path);
		},
		onIntersect: function(e){
			const effects = e[0].target.attributes['name'].value;
			
			if(e[0].isIntersecting ){
				e[0].target.className = e[0].target.className.replace('elementHide', effects);
			}else{
				e[0].target.className = e[0].target.className.replace(effects, 'elementHide');
			}
		},
	},
	computed: {
		iconXL: function(){
			return this.$vuetify.breakpoint.mdAndUp? true : false;
		},
		iconLG: function(){
			return this.$vuetify.breakpoint.smAndDown? true : false;
		},
	}
}
</script>

<style scoped>
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .overflowHide{
		overflow : hidden;
	}
	
    >>> .serviceTitle {
		text-transform: uppercase;
		letter-spacing:1px;
		font-weight:400;
		font-size:1.25rem;
    }
	
	>>> .serviceTitle2 {
		text-transform: uppercase;
		letter-spacing:1px;
		font-weight:500;
		font-size:1.30rem;
    }
</style>