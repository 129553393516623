<template>
	<v-container fluid class="px-0 mx-0">
		<v-card
			elevation="0"
			v-intersect="pageViewIn"
			:min-height="this.$store.getters.pageHeight"
			width="100%"
			class="ma-0 pa-0"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
				class="mt-16"
			>
				<v-col
					cols="12"
					class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text-center text-uppercase font-weight-regular"
					v-bind:class="{ animate__animated : pageActive, animate__slideInDown : pageActive, elementHide : !pageActive }"
				>
					{{ page }}
				</v-col>
				
				<v-col
					cols="11" sm="11" md="10" lg="9" xl="8"
					class="mb-5 text-center font-weight-light text-uppercase text-subtitle-2 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6"
					v-bind:class="{ animate__animated : pageActive, animate__slideInUp : pageActive, elementHide : !pageActive }"
					style="overflow : hidden;"
				>
					{{ pageSubtitle }}
				</v-col>
			</v-row>
			
			<v-row
				no-gutters
				align="center"
				justify="center"
			>
				<v-tabs centered v-model="galleryTab">
					<v-tab key="0" @click="tabClick">Facilities</v-tab>
					<v-tab key="1" @click="tabClick">Staff</v-tab>
					
					<v-tabs-items v-model="galleryTab" v-intersect="onIntersect">
						<v-tab-item key="0">
							
							<v-row justify="center" no-gutters>
								<v-col cols="12">
									<v-card elevation="0">
										<v-container fluid class="px-0 mx-0">
											<v-row no-gutters>
												<v-col
													v-for="i in galleryImages"
													:key="i"
													class="d-flex child-flex"
													cols="6" sm="6" md="4" lg="3" xl="3"
												>
													<v-card flat tile elevation="0" class="flip-card" :height="galleryImageH" v-intersect="onIntersectImage">
													
														<div class="flip-card-inner" ref="cardTab1">
														
															<div class="flip-card-front">
																<v-hover v-slot:default="{ hover }">
																	<v-card flat tile class="d-flex" elevation="0">
																		<v-img
																			:src="require(`@/assets/images/facility/${facilityItems[cardImages[i-1].front].image}`)"
																			:lazy-src="require(`@/assets/images/lazyload.jpg`)"
																			aspect-ratio="1"
																			class="grey lighten-2"
																			:height="galleryImageH"
																			ref="card1"
																		>
																			<template v-slot:placeholder>
																				<v-row
																					class="fill-height ma-0"
																					align="center"
																					justify="center"
																				>
																					<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
																				</v-row>
																			</template>
																			
																			<v-expand-transition>
																				<div
																					v-if="hover"
																					class="d-flex transition-fast-in-fast-out v-card--reveal text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 white--text"
																					style="height: 100%;"
																				>
																					{{ facilityItems[cardImages[i-1].front].title }}
																				</div>
																			</v-expand-transition>

																		</v-img>
																	</v-card>
																</v-hover>
															</div>
															
															<div class="flip-card-back">
																<v-hover v-slot:default="{ hover }">
																	<v-card flat tile class="d-flex" elevation="0">
																		<v-img
																			:src="require(`@/assets/images/facility/${facilityItems[cardImages[i-1].back].image}`)"
																			:lazy-src="require(`@/assets/images/lazyload.jpg`)"
																			aspect-ratio="1"
																			class="grey lighten-2"
																			:height="galleryImageH"
																		>
																			<template v-slot:placeholder>
																				<v-row
																					class="fill-height ma-0"
																					align="center"
																					justify="center"
																				>
																					<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
																				</v-row>
																			</template>
																			
																			<v-expand-transition>
																				<div
																					v-if="hover"
																					class="d-flex transition-fast-in-fast-out v-card--reveal text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 white--text"
																					style="height: 100%;"
																				>
																					{{ facilityItems[cardImages[i-1].back].title }}
																				</div>
																			</v-expand-transition>

																		</v-img>
																	</v-card>
																</v-hover>
															</div>
															
														</div>
													</v-card>
													
												</v-col>
												
											</v-row>
										</v-container>
									</v-card>
								</v-col>
							</v-row>
							
						</v-tab-item>
						
						<v-tab-item key="1">
						
							<v-row justify="center" no-gutters>
								<v-col cols="12">
									<v-card elevation="0">
										<v-container fluid class="px-0 mx-0">
											<v-row no-gutters>
												<v-col
													v-for="i in galleryImages"
													:key="i"
													class="d-flex child-flex"
													cols="6" sm="6" md="4" lg="3" xl="3"
												>
													<v-card flat tile elevation="0" class="flip-card" :height="galleryImageH" v-intersect="onIntersectImage">
													
														<div class="flip-card-inner" ref="cardTab2">
														
															<div class="flip-card-front">
																<v-hover v-slot:default="{ hover }">
																	<v-card flat tile class="d-flex" elevation="0">
																		<v-img
																			:src="require(`@/assets/images/staff/${staff[cardImages2[i-1].front].image}`)"
																			:lazy-src="require(`@/assets/images/lazyload.jpg`)"
																			aspect-ratio="1"
																			class="grey lighten-2"
																			:height="galleryImageH"
																			ref="card1"
																		>
																			<template v-slot:placeholder>
																				<v-row
																					class="fill-height ma-0"
																					align="center"
																					justify="center"
																				>
																					<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
																				</v-row>
																			</template>
																			
																			<v-expand-transition>
																				<div
																					v-if="hover"
																					class="d-flex transition-fast-in-fast-out v-card--reveal text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 white--text"
																					style="height: 100%;"
																				>
																					{{ staff[cardImages2[i-1].front].title }}
																				</div>
																			</v-expand-transition>

																		</v-img>
																	</v-card>
																</v-hover>
															</div>
															
															<div class="flip-card-back">
																<v-hover v-slot:default="{ hover }">
																	<v-card flat tile class="d-flex" elevation="0">
																		<v-img
																			:src="require(`@/assets/images/staff/${staff[cardImages2[i-1].back].image}`)"
																			:lazy-src="require(`@/assets/images/lazyload.jpg`)"
																			aspect-ratio="1"
																			class="grey lighten-2"
																			:height="galleryImageH"
																		>
																			<template v-slot:placeholder>
																				<v-row
																					class="fill-height ma-0"
																					align="center"
																					justify="center"
																				>
																					<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
																				</v-row>
																			</template>
																			
																			<v-expand-transition>
																				<div
																					v-if="hover"
																					class="d-flex transition-fast-in-fast-out v-card--reveal text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 white--text"
																					style="height: 100%;"
																				>
																					{{ staff[cardImages2[i-1].back].title }}
																				</div>
																			</v-expand-transition>

																		</v-img>
																	</v-card>
																</v-hover>
															</div>
															
														</div>
													</v-card>
													
												</v-col>
												
											</v-row>
										</v-container>
									</v-card>
								</v-col>
							</v-row>
							
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>

import { mapGetters } from 'vuex';

var galleryInterval		= '';
var imagesDisplay		= 0;
var imagesNow			= 0;
var imagesArr			= [];
var imagesArrHistory	= [];

export default {
	data: () => ({
		page		: 'Gallery',
		path		: '/gallery',
		pageSubtitle: 'LITTLE MOMENTS, BIG MEMORIES',
		pageActive	: false,
		current		: 0,
		lastItem	: 8,
		galleryTab	: 0,
		
		facilityItems: [
			{ image: 'facility1.jpg',	title: 'Library'	},
			{ image: 'facility2.jpg',	title: 'Conference Room' },
			{ image: 'facility3.jpg',	title: 'Facility 3' },
			{ image: 'facility4.jpg',	title: 'Main Reception' },
			{ image: 'facility5.jpg',	title: 'Main Reception' },
			{ image: 'facility6.jpg',	title: 'Records Room' },
			{ image: 'facility7.jpg',	title: 'Training Room 3' },
			{ image: 'facility8.jpg',	title: 'CBT Room' },
			{ image: 'facility9.jpg',	title: 'Crewing Area' },
			{ image: 'facility10.jpg',	title: 'Conference Room' },
			{ image: 'facility11.jpg',	title: 'Globe' },
			{ image: 'facility12.jpg',	title: 'Globe' },
			{ image: 'facility13.jpg',	title: 'Alster' }
		],
		staff: [
			{ image: 'image-1.jpg',		title: 'Online Interview and Assessment Team'	},
			{ image: 'image-2.jpg',		title: 'Crewing Department' },
			{ image: 'image-3.jpg',		title: 'Training Department' },
			{ image: 'image-4.jpg',		title: 'MIS Department' },
			{ image: 'image-5.jpg',		title: 'Management Team' },
			{ image: 'image-6.jpg',		title: 'Alster Family - 2020' },
			{ image: 'image-7.jpg',		title: 'Senior Management Team' },
			{ image: 'image-8.jpg',		title: 'Alster Male Group' },
			{ image: 'image-9.jpg',		title: 'Purchasing Department' },
			{ image: 'image-10.jpg',	title: 'Orion Team' },
			{ image: 'image-11.jpg',	title: 'Alster Female Group' },
			{ image: 'image-12.jpg',	title: 'Senior Management Team' },
			{ image: 'image-13.jpg',	title: 'Alster Female Group' },
			{ image: 'image-14.jpg',	title: 'Online Training Team' },
			{ image: 'image-15.jpg',	title: 'Documentation and Liaison Team' },
			{ image: 'image-16.jpg',	title: 'Admin Department' },
			{ image: 'image-17.jpg',	title: 'Alster Family - 2020' },
			{ image: 'image-18.jpg',	title: 'CST Team' },
			{ image: 'image-19.jpg',	title: 'Management Team' },
			{ image: 'image-20.jpg',	title: 'Family Center Team' },
			{ image: 'image-21.jpg',	title: 'Senior Management Team' },
			{ image: 'image-22.jpg',	title: 'Alster Male Group' },
			{ image: 'image-23.jpg',	title: 'Strahlmann Team' },
			{ image: 'image-24.jpg',	title: 'Management Team' },
			{ image: 'image-25.jpg',	title: 'Accounting Department' },
			{ image: 'image-26.jpg',	title: 'Alster Male Group' }
		],
		cardImages: [
			{ front: 0, back: 0 },
			{ front: 1, back: 1 },
			{ front: 2, back: 2 },
			{ front: 3, back: 3 },
			{ front: 4, back: 4 },
			{ front: 5, back: 5 },
			{ front: 6, back: 6 },
			{ front: 7, back: 7 },
		],
		cardImages2: [
			{ front: 0, back: 0 },
			{ front: 1, back: 1 },
			{ front: 2, back: 2 },
			{ front: 3, back: 3 },
			{ front: 4, back: 4 },
			{ front: 5, back: 5 },
			{ front: 6, back: 6 },
			{ front: 7, back: 7 },
		],
	}),
	computed:{
		...mapGetters(['galleryImageH', 'galleryImages'])
	},
	methods: {
		pageViewIn: function(e){
			if(e[0].isIntersecting){
				if( this.$route.path != this.path )this.$router.push(this.path);
				this.pageActive = true;
			}else{
				this.pageActive = false;
			}
		},
		tabClick (){
			clearInterval(galleryInterval);
			this.galleryLoop();
		},
		onIntersect (entries) {
			if(entries[0].isIntersecting){
				this.galleryLoop();
			}else{
				clearInterval(galleryInterval);
			}
		},
		onIntersectImage: function(e){
			if(e[0].isIntersecting){
				e[0].target.className = 'flip-card v-card v-card--flat v-sheet theme--light elevation-0 rounded-0 animate__animated animate__fadeIn';
			}else{
				e[0].target.className = 'flip-card v-card v-card--flat v-sheet theme--light elevation-0 rounded-0 elementHide';
			}
		},
		galleryLoop () {
			galleryInterval = setInterval( () => {
				//create or recreate images array sequence
				if( (this.galleryImages != imagesDisplay) || imagesArr.length == 0 ){
					
					if( imagesArr.length == 0 )imagesArrHistory = [];
					imagesDisplay = this.galleryImages;
					imagesArr = [];
					
					for( var i = 1; i <= this.galleryImages; i++ ){
						if( imagesArrHistory.indexOf(i) == -1 )imagesArr.push(i);
					}
					
					imagesArr.sort(() => Math.random() - 0.5);
				}
				
				imagesNow = imagesArr[0];
				
				if( this.galleryTab == 0 ){
					if( this.lastItem >= this.facilityItems.length )this.lastItem = 0;
					
					if( this.$refs.cardTab1[imagesNow - 1].className == 'flip-card-inner' ){
						
						this.cardImages[imagesNow - 1].back = this.lastItem;
						
						this.$refs.cardTab1[imagesNow - 1].className += ' flip';
					}else{
					
						this.cardImages[imagesNow -1].front = this.lastItem;
					
						this.$refs.cardTab1[imagesNow - 1].className = 'flip-card-inner';
					}
					
					if (++this.lastItem >= this.facilityItems.length) this.lastItem = 0;
					
				}else{
					if( this.lastItem >= this.staff.length )this.lastItem = 0;
					
					if( this.$refs.cardTab2[imagesNow - 1].className == 'flip-card-inner' ){
						
						this.cardImages2[imagesNow - 1].back = this.lastItem;
						
						this.$refs.cardTab2[imagesNow - 1].className += ' flip';
					}else{
					
						this.cardImages2[imagesNow -1].front = this.lastItem;
					
						this.$refs.cardTab2[imagesNow - 1].className = 'flip-card-inner';
					}
					
					if (++this.lastItem >= this.staff.length) this.lastItem = 0;
					
				}
				
				
				imagesArr.shift();
				imagesArrHistory.push(imagesNow);
				
			}, 2000);
		}
		
	},
	mounted() {
		this.lastItem = this.galleryImages;
	},
	beforeDestroy(){
		clearInterval(galleryInterval);
	},
}
</script>

<style scoped>
    >>> .opacity {
		opacity: 0.1;
    }
	
	>>> .elementHide{
		opacity: 0;
	}
	
	>>> .v-card--reveal {
		align-items: center;
		bottom: 0;
		justify-content: center;
		position: absolute;
		width: 100%;
		background-color: rgba(67, 67, 67, .5);
	}

	.flip-card {
		background-color: transparent;
		border: 1px solid #f1f1f1;
		perspective: 1000px; /* Remove this if you don't want the 3D effect */
	}

	/* This container is needed to position the front and back side */
	.flip-card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		transition: transform 0.8s;
		transform-style: preserve-3d;
	}

	.flip{
		transform: rotateY(180deg);
	}
	
	.flippa{
		transform: rotateY(360deg);
	}
	
	/* Position the front and back side */
	.flip-card-front, .flip-card-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
	}

	/* Style the front side (fallback if image is missing) */
	.flip-card-front {
		background-color: #bbb;
		color: black;
	}

	/* Style the back side */
	.flip-card-back {
		background-color: dodgerblue;
		color: white;
		transform: rotateY(180deg);
	}
</style>