import { PAGE_RESIZE, PAGE_SNACKBAR_CLOSE, PAGE_NOTIFICATION, PAGE_FEEDBACK_SUCCESS, PAGE_ERROR_SERVER, PAGE_ERROR_CONNECTION } from '../actions/page'

import { PAGE_DIALOG_ON, PAGE_DIALOG_OFF, PAGE_SEND } from '../actions/page'

const state = { 
	pageHeight		: window.innerHeight,
	pageWidth		: window.innerWidth,
	
	logoWidth		: 160,
	logoHeight		: 40,
	
	portFolioSize	: 220,
	portFolioWidth	: 10,
	
	aboutUsImageH	: 200,
	
	galleryImageH	: 100,
	
	newsMainImageW	: 100,
	newsMainImageH	: 100,
	
	newsListImageW	: 100,
	newsListImageH	: 100,
	
	trainingWindowH	: 100,
	trainingWindowW	: 100,
	trainingImageW	: 100,
	
	snackBar		: false,
	snackBarColor	: 'success',
	snackBarIcon	: 'mdi-check',
	snackBarMsg		: '.',
	
	galleryImages	: 8,
	
	pageDialog		: false,
	pageDialogType	: '',
}

const getters = {
	pageHeight		: state => state.pageHeight,
	pageWidth		: state => state.pageWidth,
	
	logoWidth		: state => state.logoWidth,
	logoHeight		: state => state.logoHeight,
	
	portFolioSize	: state => state.portFolioSize,
	portFolioWidth	: state => state.portFolioWidth,
	
	aboutUsImageH	: state => state.aboutUsImageH,
	
	galleryImageH	: state => state.galleryImageH,
	
	newsMainImageW	: state => state.newsMainImageW,
	newsMainImageH	: state => state.newsMainImageH,
	
	newsListImageW	: state => state.newsListImageW,
	newsListImageH	: state => state.newsListImageH,
	
	trainingWindowH	: state => state.trainingWindowH,
	trainingWindowW	: state => state.trainingWindowW,
	trainingImageW	: state => state.trainingImageW,
	
	snackBar		: state => state.snackBar,
	snackBarColor	: state => state.snackBarColor,
	snackBarIcon	: state => state.snackBarIcon,
	snackBarMsg		: state => state.snackBarMsg,
	
	galleryImages	: state => state.galleryImages,
	
	pageDialog		: state => state.pageDialog,
	pageDialogType	: state => state.pageDialogType,
}

const actions = {
	[PAGE_RESIZE]: ({commit}) => {
		commit(PAGE_RESIZE)
	},
	[PAGE_SNACKBAR_CLOSE]: ({commit}) => {
		commit(PAGE_SNACKBAR_CLOSE)
	},
	[PAGE_SEND]: ({commit}) => {
		commit(PAGE_SEND)
	},
	[PAGE_NOTIFICATION]: ({commit}) => {
		commit(PAGE_NOTIFICATION)
	},
	[PAGE_FEEDBACK_SUCCESS]: ({commit}) => {
		commit(PAGE_FEEDBACK_SUCCESS)
	},
	[PAGE_ERROR_SERVER]: ({commit}) => {
		commit(PAGE_ERROR_SERVER)
	},
	[PAGE_ERROR_CONNECTION]: ({commit}) => {
		commit(PAGE_ERROR_CONNECTION)
	},
	
	[PAGE_DIALOG_ON]: ({commit}, data) => {
		commit(PAGE_DIALOG_ON, data.val)
	},
	[PAGE_DIALOG_OFF]: ({commit}) => {
		commit(PAGE_DIALOG_OFF)
	},
}

const mutations = {
	[PAGE_RESIZE]: (state) => {
		state.pageHeight = ( window.innerHeight > 300 )? window.innerHeight : 300;
		state.pageWidth = ( window.innerWidth > 300 )? window.innerWidth : 300;
		
		var breakpointName = '';
		
		//recreate vuetify breakpoint to fix delay
		if(window.innerWidth <= 600){
			breakpointName = 'xs';
		}else if(window.innerWidth > 600 && window.innerWidth <= 960){
			breakpointName = 'sm';
		}else if(window.innerWidth > 960 && window.innerWidth <= 1264){
			breakpointName = 'md';
		}else if(window.innerWidth > 1264 && window.innerWidth <= 1904){
			breakpointName = 'lg';
		}else{
			breakpointName = 'xl';
		}
		
		switch (breakpointName) {
			case 'xs':
			
				state.logoWidth = 115;
				state.logoHeight = 35;
	
				state.portFolioSize	= 80;
				state.portFolioWidth= 5;
				
				state.aboutUsImageH = 590,
				
				state.trainingImageW = (window.innerWidth/12) * 9;
				state.trainingWindowW = window.innerWidth;
				state.trainingWindowH = (window.innerHeight/100) * 70;
		
				state.newsMainImageW = window.innerWidth;
				state.newsMainImageH = (window.innerWidth/100) * 50;
		
				state.newsListImageW = (window.innerWidth/2);
				state.newsListImageH = ((window.innerWidth/2)/100) * 60;
				
				state.galleryImageH = ((window.innerWidth/2)/100) * 60;
				state.galleryImages = 6;
			break;
			case 'sm':
			
				state.logoWidth = 140;
				state.logoHeight = 35;
	
				state.portFolioSize	= 100;
				state.portFolioWidth= 6;
				
				state.aboutUsImageH = 540,
				
				state.trainingImageW = (window.innerWidth/12) * 8;
				state.trainingWindowW = window.innerWidth;
				state.trainingWindowH = (window.innerHeight/100) * 70;
		
				state.newsMainImageW = (window.innerWidth/12) * 10;
				state.newsMainImageH = (state.newsMainImageW/100) * 50;
		
				state.newsListImageW = (window.innerWidth/12) * 5;
				state.newsListImageH = (state.newsListImageW/100) * 60;
				
				state.galleryImageH = ((window.innerWidth/2)/100) * 60;
				state.galleryImages = 6;
			break;
			case 'md':
				
				state.logoWidth = 140;
				state.logoHeight = 35;
	
				state.portFolioSize	= 120;
				state.portFolioWidth= 7;
				
				state.aboutUsImageH = 450,
				
				state.trainingImageW = (window.innerWidth/12) * 4;
				state.trainingWindowW = window.innerWidth;
				state.trainingWindowH = (window.innerHeight/100) * 70;
		
				state.newsMainImageW = (window.innerWidth/12) * 10;
				state.newsMainImageH = (state.newsMainImageW/100) * 40;
		
				state.newsListImageW = (window.innerWidth/12) * 5;
				state.newsListImageH = (state.newsListImageW/100) * 50;
				
				state.galleryImageH = ((window.innerWidth/3)/100) * 60;
				state.galleryImages = 6;
			break;
			case 'lg':
				
				state.logoWidth = 160;
				state.logoHeight = 40;
	
				state.portFolioSize	= 165;
				state.portFolioWidth= 9;
				
				state.aboutUsImageH = 520,
				
				state.trainingImageW = ((window.innerWidth/12) * 11) / 3.5;
				state.trainingWindowW = (window.innerWidth/12) * 11;
				state.trainingWindowH = (window.innerHeight/100) * 70;
		
				state.newsMainImageW = (window.innerWidth/12) * 5;
				state.newsMainImageH = (state.newsMainImageW/100) * 60;
		
				state.newsListImageW = state.newsMainImageW/2;
				state.newsListImageH = (state.newsMainImageH/2) - 4;
				
				state.galleryImageH = ((window.innerWidth/4)/100) * 60;
				state.galleryImages = 8;
			break;
			case 'xl':
				
				state.logoWidth = 160;
				state.logoHeight = 40;
	
				state.portFolioSize	= 165;
				state.portFolioWidth= 9;
				
				state.aboutUsImageH = 520,
				
				state.trainingImageW = ((window.innerWidth/12) * 11) / 4;
				state.trainingWindowW = (window.innerWidth/12) * 10;
				state.trainingWindowH = (window.innerHeight/100) * 70;
		
				state.newsMainImageW = (window.innerWidth/12) * 5;
				state.newsMainImageH = (state.newsMainImageW/100) * 60;
		
				state.newsListImageW = state.newsMainImageW/2;
				state.newsListImageH = (state.newsMainImageH/2) - 4;
				
				state.galleryImageH = ((window.innerWidth/4)/100) * 60;
				state.galleryImages = 8;
			break;
        }
	
	},
	[PAGE_SNACKBAR_CLOSE]: (state) => {
		state.snackBar = false;
	},
	[PAGE_SEND]: (state) => {
		state.snackBar		= true;
		state.snackBarColor	= 'success';
		state.snackBarIcon	= 'mdi-check';
		state.snackBarMsg	= 'Successfully sent';
		
		setTimeout( () => { state.snackBar = false; }, 6000);
	},
	[PAGE_NOTIFICATION]: (state) => {
		state.snackBar		= true;
		state.snackBarColor	= 'success';
		state.snackBarIcon	= 'mdi-check';
		state.snackBarMsg	= 'Thank you for subscribing';
		
		setTimeout( () => { state.snackBar = false; }, 6000);
	},
	[PAGE_FEEDBACK_SUCCESS]: (state) => {
		state.snackBar		= true;
		state.snackBarColor	= 'success';
		state.snackBarIcon	= 'mdi-check';
		state.snackBarMsg	= 'Thank you for your feedback';
		
		setTimeout( () => { state.snackBar = false; }, 6000);
	},
	[PAGE_ERROR_SERVER]: (state) => {
		state.snackBar		= true;
		state.snackBarColor	= 'error';
		state.snackBarIcon	= 'mdi-alert';
		state.snackBarMsg	= 'Server Error, try again';
		
		setTimeout( () => { state.snackBar = false; }, 6000);
	},
	[PAGE_ERROR_CONNECTION]: (state) => {
		state.snackBar		= true;
		state.snackBarColor	= 'warning';
		state.snackBarIcon	= 'mdi-alert-circle';
		state.snackBarMsg	= 'Connection Error, try again';
		
		setTimeout( () => { state.snackBar = false; }, 6000);
	},
	[PAGE_DIALOG_ON]: (state, data) => {
		state.pageDialog	= true;
		state.pageDialogType= data;
	},
	[PAGE_DIALOG_OFF]: (state) => {
		state.pageDialog	= false;
		state.pageDialogType= '';
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}