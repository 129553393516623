// eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_ERROR, AUTH_ADMIN, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_UNAUTHORIZED, AUTH_CLEAR, AUTH_NETWORKERROR, AUTH_REGISTER, LOGIN_OPEN, LOGIN_CLOSE, OFF_WELCOME_MESSAGE } from '../actions/auth'
import axios from 'axios'

const state = {
	token			: localStorage.getItem('user-token') || '',
	status			: '',
	hasLoadedOnce	: false,
	loginActive		: false,
	isAdmin			: false,
	welcomeMessage	: false,
}

const getters = {
	isAuthenticated	: state => !!state.token,
	authStatus		: state => state.status,
	authError		: state => state.status == 'error',
	tokenKey		: state => state.token,
	loginActive		: state => state.loginActive,
	isAdmin			: state => state.isAdmin,
	welcomeMessage	: state => state.welcomeMessage,
}

const actions = {
	[OFF_WELCOME_MESSAGE]: ({commit}) => {
		commit(OFF_WELCOME_MESSAGE)
	},
	[AUTH_ADMIN]: ({commit}) => {
		commit(AUTH_ADMIN)
	},
	[AUTH_REQUEST]: ({commit}, user) => {
		return new Promise((resolve, reject) => { // The Promise used for router redirect in login
			commit(AUTH_REQUEST)
			axios('auth/login', { params: { user: user.loginUser, password: user.loginPassword }, method: 'POST'})
			.then(function (resp){
				
				if( typeof resp.data.error !== 'undefined' && resp.data.error != '' ){
					commit(AUTH_ERROR, resp)
					localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
					localStorage.removeItem('user-admin')
				}else if( typeof resp.data.admin !== 'undefined' && resp.data.admin ){
					commit(AUTH_ADMIN)
					
					const token = resp.data.token;
					
					commit(AUTH_SUCCESS, resp.data)
					localStorage.setItem('user-token', token) // store the token in localstorage
					localStorage.setItem('user-admin', true)
					if (token) { //set uthorization token for every request
						axios.defaults.headers.common['Authorization'] = 'Bearer ' +token;
					}
					
					resolve()
				}else{
					const token = resp.data.token
					
					commit(AUTH_SUCCESS, resp.data)
					localStorage.setItem('user-token', token) // store the token in localstorage
					localStorage.removeItem('user-admin')
					
					if (token) { //set uthorization token for every request
						axios.defaults.headers.common['Authorization'] = 'Bearer ' +token;
					}
					
					resolve()
				}
			})
			.catch(err => {
				commit(AUTH_NETWORKERROR)
				console.clear();
				
				reject(err)
			})
		})
		
	},
	[AUTH_REGISTER]: ({commit}, data) => {
		
		console.log(data);
		localStorage.setItem('user-token', data.token) // store the token in localstorage
		
		if (data.token) { //set uthorization token for every request
			axios.defaults.headers.common['Authorization'] = 'Bearer ' +data.token;
		}
		
		commit(AUTH_SUCCESS, data)
	},
	[AUTH_LOGOUT]: ({commit}) => {
		return new Promise((resolve) => {
			commit(AUTH_LOGOUT)
			localStorage.removeItem('user-token')
			localStorage.removeItem('user-admin')
			resolve()
		})
	},
	[AUTH_UNAUTHORIZED]: ({commit}) => {
		commit(AUTH_LOGOUT)
		commit(AUTH_UNAUTHORIZED)
		localStorage.removeItem('user-token')
		localStorage.removeItem('user-admin')
	},
	[AUTH_CLEAR]: ({commit}) => {
		commit(AUTH_CLEAR)
	},
	[AUTH_NETWORKERROR]: ({commit}) => {
		commit(AUTH_NETWORKERROR)
	},
	[LOGIN_OPEN]: ({commit}) => {
		commit(LOGIN_OPEN)
	},
	[LOGIN_CLOSE]: ({commit}) => {
		commit(LOGIN_CLOSE)
	},
}

const mutations = {
	[OFF_WELCOME_MESSAGE]: (state) => {
		state.welcomeMessage = false;
	},
	[AUTH_ADMIN]: (state) => {
		state.isAdmin = true;
	},
	[LOGIN_OPEN]: (state) => {
		state.loginActive = true;
	},
	[LOGIN_CLOSE]: (state) => {
		state.loginActive = false;
	},
	[AUTH_REQUEST]: (state) => {
		state.status = 'loading';
	},
	[AUTH_SUCCESS]: (state, data) => {
		state.status = 'success';
		state.token = data.token;
		state.hasLoadedOnce = true;
		state.loginActive = false;
		
		if(typeof data.msg !== 'undefined')state.welcomeMessage = data.msg;
	},
	[AUTH_ERROR]: (state) => {
		state.status = 'error';
		state.hasLoadedOnce = true;
	},
	[AUTH_UNAUTHORIZED]: (state) => {
		state.status = 'unauthorized';
		state.isAdmin = false;
	},
	[AUTH_LOGOUT]: (state) => {
		state.isAdmin = false;
		state.token = '';
	},
	[AUTH_NETWORKERROR]: (state) => {
		state.status = 'networkerror';
	},
	[AUTH_CLEAR]: (state) => {
		state.status = '';
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}