<template>
	<v-footer
		dark
		padless
	>
		<v-container fluid class="pa-0 ma-0">
			<v-row no-gutters class="primary px-5" align="end" justify="end">
				<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="text-h6 font-weight-bold">
					<span class="pr-2">GET SOCIAL</span>
					
					<v-btn icon
						color="white"
						@click="gotoFacebook"
						class="my-2"
					>
						<v-icon large>mdi-facebook</v-icon>
					</v-btn>
				</v-col>
				
				<v-col cols="12" sm="6" class="text-h6 font-weight-bold hidden-xs-only hidden-md-and-up">
					<v-row no-gutters class="pb-2">
						<v-col cols="auto" class="pr-2">CERTIFICATIONS</v-col>
						
						<v-col cols="auto">
						<v-img
							:src="require('@/assets/images/logo/isoLogo.png')"
							width="35"
							height="35"
						></v-img>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			
			<v-row no-gutters class="px-5 mt-2">
				<v-col cols="12" sm="6" md="3" lg="3" xl="3">
					<span class="font-weight-bold text-h6">
						GET IN TOUCH<br/>
					</span>	
					<span class="caption font-weight-light">
						<v-icon small>mdi-map-marker</v-icon> 2/F 2053 Bldg. Edison St. Makati City <span class="hidden-sm-and-down">Philippines</span><span class="hidden-md-and-up">Phils.</span><br/>
						<v-icon small>mdi-phone</v-icon> +63 2 8887 - 6010 to 12<br/>
						<v-icon small>mdi-email</v-icon> aissi@alsterph.com<br/>
					</span>
				</v-col>
				
				<v-col cols="12" sm="6" md="3" lg="3" xl="3">
					<span class="font-weight-bold text-h6">
						PARTNER<br/>
					</span>	
					<span class="caption font-weight-light">
						<v-icon small>mdi-account</v-icon> Alster Marine Services GmbH<br/>
						<v-icon small>mdi-map-marker</v-icon> Marckmannstr. 36, 20539 Hamburg, Germany<br/>
						<v-icon small>mdi-phone</v-icon> +49 (0)40 – 78 38 39<br/>
						<v-icon small>mdi-email</v-icon> hamburg@alstermarine.de<br/>
						<v-icon small>mdi-web</v-icon> <a href="https://alstermarine.de">www.alstermarine.de</a><br/>
					</span>
				</v-col>
				
				<v-col cols="3" md="3" lg="3" xl="3" class="hidden-sm-only text-sm-center text-md-center text-lg-center text-xl-center">
					<span class="font-weight-bold text-h6">
						CERTIFICATIONS<br/>
					</span>
					<v-img
						:src="require('@/assets/images/logo/isoLogo.png')"
						:width="$vuetify.breakpoint.xsOnly? 40 : 70"
						:height="$vuetify.breakpoint.xsOnly? 40 : 70"
						class="mx-md-auto mx-lg-auto mx-xl-auto"
					></v-img>
				</v-col>
				
				<v-col cols="12" sm="3" md="4" lg="3" xl="3">
					<span class="font-weight-bold text-h6">
						GET NOTIFICATIONS<br/>
					</span>
					<span class="caption font-weight-light">
						Be the first to know our latest job opening
						<v-text-field
							append-icon="mdi-send"
							dense
							outlined
							placeholder="Email Address"
							v-model="email"
							:error="error"
							:error-messages="errorMsg"
							:loading="loadingEmail"
							@blur="clear"
							@keydown="clear"
							@click="clear"
							@click:append="getNotification"
						></v-text-field>
					</span>
				</v-col>
			</v-row>
			
			<v-divider dark class="my-1"></v-divider>
			
			<v-row no-gutters>
				<v-col cols="12" class="text-center">
					<v-btn text x-small @click="showPrivacyPolicy">Privacy Policy</v-btn> | 
					<v-btn text x-small @click="showTermsOfUse">Terms of Use</v-btn><br/>
					<span class="caption font-weight-light">&#169; {{ new Date().getFullYear() }} ALSTER. ALL RIGHTS RESERVED.</span>
				</v-col>
				
				<v-dialog
					v-model="pageDialog"
					width="650"
					scrollable
					:fullscreen="$vuetify.breakpoint.xsOnly"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							{{ dialogTitle[pageDialogType] }}
							<v-spacer></v-spacer>
							
							<v-btn icon
								@click="hideDialog()"
								class="white--text"
							>
								<v-icon small dark>mdi-close</v-icon>
							</v-btn>
						</v-card-title>

						<v-card-text v-if="pageDialogType == 'terms'" v-html="termsOfUse.join('')"></v-card-text>
						<v-card-text v-else v-html="policyPrivacy.join('')"></v-card-text>
						
						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								class="mr-5"
								@click="hideDialog()"
							>
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</v-container>
	</v-footer>
	
</template>

<script>

import { mapGetters } from 'vuex';

import { PAGE_DIALOG_ON, PAGE_DIALOG_OFF } from '@/store/actions/page';
import { PAGE_NOTIFICATION, PAGE_SNACKBAR_CLOSE } from '@/store/actions/page';

export default {
	name: 'FOOTER',
	data() {
		return {
			dialogTitle		: {
				'terms' 	: 'Terms of Use',
				'policy'	: 'Privacy Policy',
			},
			error			: false,
			errorMsg		: '',
			email			: '',
			loadingEmail	: false,
			termsOfUse		: [
				'<br/><b>Last Updated: November 4, 2020</b><br/><br/>',
				'Please read these Terms of Use carefully before using this website operated by Alster International Shipping Services Inc.',
				' (AISSI).<br/><br/>Your access to and use of the service is conditioned on your acceptance of and compliance with these terms.',
				' These terms apply to all our visitors, users and others who access or use the service.',
				'<br/><br/>By accessing or using the service you agree to be bound by these terms.',
				' If you disagree with any part of the terms then you may not access the service.',
				'<br/><br/><b>Termination</b><br/><br/>',
				'We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.',
				'<br/><br/>App provisions of the terms which by their nature should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.'
			],
			policyPrivacy	: [
				'<br/><b>Last updated: November 4, 2020</b><br/><br/>',
				'<b>Alster International Shipping Services Inc. (AISSI)</b> operates www.alsterph.com.',
				' This page informs you of our policies regarding the collection, use and disclosure of personal information we receive from users of the site.',
				'<br/><br/>We use your personal information only for providing and improving the site.',
				' By using the site, you agree to the collection and use of information in accordance with this policy.',
				'<br/><br/><b>Information Collection and Use</b><br/><br/>',
				'While using our site, we may ask you to provide us your personal identifiable information that can be used to contact or identify or process your application.',
				'<br/><br/>The personal information we collect include the following:<br/><br/>',
				'<b>Name</b>: We collect full name to validate your identity and legitimacy.<br/><br/>',
				'<b>Personal Information</b>: We collect personal information such as birthdate, place of birth, gender, civil status, nationality, religion, height, weight',
				'eyes and hair color, shoe and cover all size, SSS number, PAG-IBIG number, TIN number and PhilHealth number through our application page.<br/><br/>',
				'<b>Contact Information</b>: We collect contact informations such as present and temporary address, emails, telephone and mobile phone number through our application page.<br/><br/>',
				'<b>Other Information</b>: We also collect family, education, employment, documents and pre-interview anwers through our application page.<br/><br/>',
			]
		}
	},
	methods: {
		gotoFacebook() {
			window.open('https://www.facebook.com/Alsterph1');
		},
		clear (){
			this.error = false;
			this.errorMsg = '';
		},
		getNotification () {
			if( /.+@.+\..+/.test(this.email) && this.email != '' ){
				
				this.$store.dispatch(PAGE_SNACKBAR_CLOSE);
				this.loadingEmail = true;
				
				this.$http.post('web/subscribe', { email: this.email})
				.then(() => {
					this.email = '';
					this.$store.dispatch(PAGE_NOTIFICATION);
				})
				.catch(() => {
					this.error = true;
					this.errorMsg = 'Error encounter, try again';
				})
				.finally(() => {
					this.loadingEmail = false;
				});
			}else{
				this.error = true;
				this.errorMsg = 'Enter valid email';
			}
		},
		hideDialog (){
			this.$store.dispatch(PAGE_DIALOG_OFF);
		},
		showPrivacyPolicy (){
			const val = 'policy';
			this.$store.dispatch(PAGE_DIALOG_ON, { val });
		},
		showTermsOfUse (){
			const val = 'terms';
			this.$store.dispatch(PAGE_DIALOG_ON, { val });
		},
	},
	computed: {
		...mapGetters(['pageDialog', 'pageDialogType'])
	},
}
</script>